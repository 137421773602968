import React from "react";
import { useState, ChangeEvent, useEffect } from "react";
import { sendMailEvaluation4Bart, updateScoringData4Feedback } from "../../api/scoring";
import FeedbackChoice from "../../components/Button/FeedbackButton";
import { LoaderResponse } from "../../components/Loader";
import { COLORS } from "../../constants/ColorsData";
import "../../styles/BartTestEmailForm.css";

interface QuestionData {
    question: string;
    key: string[];
}

interface QuestionList {
    [criteria: string]: QuestionData[];
}

var default_mail_segment_key = "accroche"
var default_criteria = "pertinence"
var default_question = "L’accroche mentionne-t-elle le post que la personne a publié sur LinkedIn ?"


const BartTestEmailForm = () => {
    const resultRef = React.useRef<HTMLDivElement | null>(null);
    const [showQuestion, setShowQuestion] = useState(false)

    const questionsByCriteria = (mailSegmentKey: string) => {
        const subcategory: string = formData.selectedSubcategory
        const default_data = {
            "pertinence": [
                {
                    "question": "L’accroche mentionne-t-elle le post que la personne a publié sur LinkedIn ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "L’accroche mentionne-t-elle un sujet abordé dans le post du destinataire ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "L’accroche mentionne-t-elle un événement ou une activité précisée dans le post ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "L’accroche reprend-elle des mots-clés du post, tels que {Context_keyword} ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "L'accroche mentionne-t-elle un produit ou une solution spécifique ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "L’accroche reformule-t-elle l’idée principale du post ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "L’accroche mentionne-t-elle un événement auquel {name} a assisté ou auquel il/elle participera ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "L’accroche fait-elle allusion à une priorité pour {name} ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "L’accroche utilise-t-elle des informations tirées du post LinkedIn ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "L'accroche respecte-t-elle le sujet principal du post LinkedIn ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "L'accroche met-elle en avant un défi, une opportunité ou un succès mentionné dans le post ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "L’accroche inclut-elle un élément en lien avec son poste ou son entreprise ?",
                    "key": ["accroche", "person_job", "mail_context"]
                },
                {
                    "question": "L'accroche met-elle en avant un aspect positif ou un succès présenté dans le post de {name} ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "L’accroche contient-elle un acronyme présent dans le post ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "L’accroche mentionne-t-elle {company} ?",
                    "key": ["accroche", "company"]
                },
                {
                    "question": "L’accroche utilise-t-elle les termes liés à {company_domain_activity} ?",
                    "key": ["accroche", "company_domain_activity"]
                },
                {
                    "question": "Le domaine d’activité est-il utilisé pour établir une connexion avec le destinataire ?",
                    "key": ["accroche", "company_domain_activity"]
                },
                {
                    "question": "L’accroche évoque-t-elle des innovations pertinentes pour le secteur {company_domain_activity} ?",
                    "key": ["accroche", "company_domain_activity"]
                },
                {
                    "question": "L'accroche traite-t-elle de l’utilisation de l’IA dans le domaine de {company_domain_activity} ?",
                    "key": ["accroche", "company_domain_activity"]
                },
                {
                    "question": "L’accroche fait-elle référence à un projet particulier de {name} ?",
                    "key": ["accroche", "mail_context", "person_job"]
                },
                {
                    "question": "L’accroche semble-t-elle adaptée pour un {person_job} ?",
                    "key": ["accroche", "mail_context", "person_job"]
                },
                {
                    "question": "L'accroche évoque-t-elle des objectifs pour un {person_job} (tels que Croissance, Performance, Stratégie, Innovation, Résultats) ?",
                    "key": ["accroche", "mail_context", "person_job"]
                },
                {
                    "question": "L'accroche mentionne-t-elle des défis liés au rôle de {person_job} (tels que objectifs, motivation, marché, compétences et innovation) ?",
                    "key": ["accroche", "mail_context", "person_job"]
                },
                {
                    "question": "L'accroche reflète-t-elle les responsabilités de {name} en tant que {person_job} ?",
                    "key": ["accroche", "mail_context", "person_job"]
                },
                {
                    "question": "L’accroche donne-t-elle une raison claire pour que {name} se sente personnellement concerné(e) ?",
                    "key": ["accroche", "person_job", "mail_context"]
                }
            ],
            "clarity": [
                {
                    "question": "L’accroche fait-elle moins de 35 mots ?",
                    "key": ["accroche"]
                },
                {
                    "question": "Le vocabulaire utilisé est-il compréhensible par le destinataire sans ambiguïté ?",
                    "key": ["accroche"]
                },
                {
                    "question": "L’accroche évite-t-elle les termes trop techniques ?",
                    "key": ["accroche"]
                },
                {
                    "question": "La phrase est-elle facile à comprendre ?",
                    "key": ["accroche"]
                },
                {
                    "question": "L’accroche utilise-t-elle des mots simples et directs ?",
                    "key": ["accroche"]
                },
                {
                    "question": "Les phrases de l’accroche s’enchaînent-elles logiquement ?",
                    "key": ["accroche"]
                },
                {
                    "question": "L’accroche suit-elle une progression logique (sujet-verbe-complément) ?",
                    "key": ["accroche"]
                },
                {
                    "question": "Aucune contradiction n’est-elle présente dans les phrases de l’accroche ?",
                    "key": ["accroche"]
                },
                {
                    "question": "L’accroche évite-t-elle des idées vagues ou des affirmations floues ?",
                    "key": ["accroche"]
                },
                {
                    "question": "Les phrases restent-elles centrées sur le destinataire ?",
                    "key": ["accroche", "person_job", "mail_context"]
                },
                {
                    "question": "L’accroche va-t-elle droit au but ?",
                    "key": ["accroche"]
                },
                {
                    "question": "Les éléments mentionnés dans l’accroche sont-ils précis ?",
                    "key": ["accroche"]
                },
                {
                    "question": "L’accroche cite-t-elle des termes spécifiques pour un {person_job} ou au {company_domain_activity} ?",
                    "key": ["accroche", "mail_context", "company_domain_activity", "person_job"]
                },
                {
                    "question": "Aucune phrase de l’accroche n’est-elle abstraite ?",
                    "key": ["accroche"]
                },
                {
                    "question": "L’accroche contient-elle uniquement les informations essentielles ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "L’accroche commence-t-elle par une phrase claire ?",
                    "key": ["accroche"]
                },
                {
                    "question": "L’accroche suit-elle une structure logique et fluide ?",
                    "key": ["accroche"]
                },
                {
                    "question": "Les idées principales sont-elles présentées dès le début de l’accroche ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "Les mots-clés comme {Context_keyword} ou éléments importants apparaissent-ils au début de chaque phrase ?",
                    "key": ["accroche", "mail_context", "company_value"]
                },
                {
                    "question": "Aucune partie de l’accroche n’est-elle répétitive ?",
                    "key": ["accroche"]
                },
                {
                    "question": "L’accroche évite-t-elle les acronymes ou abréviations non expliquées ?",
                    "key": ["accroche"]
                },
                {
                    "question": "L’accroche évite-t-elle les répétitions inutiles ?",
                    "key": ["accroche"]
                },
                {
                    "question": "La construction grammaticale est-elle correcte et fluide ?",
                    "key": ["accroche"]
                },
                {
                    "question": "L’accroche présente-t-elle les informations de manière concise et sans surcharge ?",
                    "key": ["accroche"]
                },
                {
                    "question": "Les mots utilisés sont-ils précis et évitent-ils les généralités ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "L’accroche reste-t-elle claire tout en étant personnalisée ?",
                    "key": ["accroche", "mail_context", "person_job"]
                }
            ],
            "captivant": [
                {
                    "question": "L’accroche suscite-t-elle la curiosité en faisant un lien avec un sujet du destinataire ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "L’accroche donne-t-elle envie d’en savoir plus sur le sujet abordé ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "L'accroche donne-t-elle l'impression qu'il y a quelque chose d'important à découvrir ?",
                    "key": ["accroche"]
                },
                {
                    "question": "L’accroche donne-t-elle l’impression d’un sujet inédit ou novateur ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "L’accroche met-elle en avant un défi ou un problème à résoudre ?",
                    "key": ["accroche"]
                },
                {
                    "question": "L’accroche mentionne-t-elle un changement ou une nouveauté ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "L'accroche commence-t-elle avec une phrase qui intrigue ?",
                    "key": ["accroche"]
                },
                {
                    "question": "L’accroche mentionne-t-elle un événement pertinent par rapport à la proposition de valeur ?",
                    "key": ["accroche", "mail_context", "sender_value_proposition"]
                },
                {
                    "question": "L’accroche fait-elle un lien explicite avec le contexte du destinataire ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "L'accroche reflète-t-elle une compréhension des défis du destinataire ?",
                    "key": ["accroche", "person_job"]
                },
                {
                    "question": "L'accroche donne-t-elle l'impression d'un message personnalisé pour le destinataire ?",
                    "key": ["accroche", "mail_context", "person_job"]
                },
                {
                    "question": "L'accroche contient-elle des éléments spécifiques au destinataire qui la différencient d’un message générique ?",
                    "key": ["accroche", "mail_context", "person_job"]
                },
                {
                    "question": "L'accroche démontre-t-elle un intérêt pour le destinataire ?",
                    "key": ["accroche", "person_job", "mail_context"]
                },
                {
                    "question": "L'accroche donne-t-elle l'impression que l'émetteur connaît bien le contexte du destinataire ?",
                    "key": ["accroche", "person_job", "mail_context"]
                },
                {
                    "question": "L'accroche fait-elle sentir au destinataire qu'il est important pour l'émetteur ?",
                    "key": ["accroche", "person_job", "mail_context"]
                },
                {
                    "question": "L’accroche adopte-t-elle une approche différente des standards des emails commerciaux ?",
                    "key": ["accroche"]
                },
                {
                    "question": "L’accroche utilise-t-elle un ton, un style ou une structure inhabituels ?",
                    "key": ["accroche"]
                },
                {
                    "question": "L’accroche évite-t-elle des phrases trop commerciales ?",
                    "key": ["accroche"]
                },
                {
                    "question": "L'accroche utilise-t-elle une métaphore ou une image frappante pour se différencier ?",
                    "key": ["accroche"]
                },
                {
                    "question": "L’accroche contient-elle des mots émotionnels forts ?",
                    "key": ["accroche"]
                },
                {
                    "question": "L'accroche montre-t-elle une attention particulière aux besoins ou aux intérêts du destinataire ?",
                    "key": ["accroche", "person_job"]
                },
                {
                    "question": "L'accroche reflète-t-elle une compréhension approfondie du secteur d’activité du destinataire ?",
                    "key": ["accroche", "company_domain_activity"]
                },
                {
                    "question": "L'accroche montre-t-elle que l'émetteur se soucie des objectifs du destinataire ?",
                    "key": ["accroche", "person_job", "mail_context"]
                },
                {
                    "question": "L'accroche propose-t-elle quelque chose de concret, sans être trop vague ou générique ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "L'accroche fait-elle sentir au destinataire qu'il/elle est au centre de l'attention ?",
                    "key": ["accroche", "person_job", "mail_context"]
                }
            ],
            "personalization": [
                {
                    "question": "La publication LinkedIn de la personne est-elle mentionnée explicitement dans l'accroche ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "Une phrase spécifique liée au contenu de la publication est-elle utilisée dans l'accroche ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "L'accroche reflète-t-elle les idées principales du post que la personne a publié ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "L'accroche est-elle en lien avec une compétence ou une expertise spécifique du destinataire ?",
                    "key": ["accroche", "mail_context", "person_job"]
                },
                {
                    "question": "L'accroche mentionne-t-elle un élément qui montre que vous avez lu le contenu en détail ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "Y a-t-il une question ou une réflexion en lien direct avec la publication ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "Le sujet de la publication est-il lié à la proposition de valeur de l’email ?",
                    "key": ["accroche", "mail_context", "sender_value_proposition"]
                },
                {
                    "question": "Une expression ou un style spécifique au post est-il repris ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "L'accroche montre-t-elle un alignement avec l’opinion de la personne dans sa publication ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "Une date ou un contexte spécifique lié à la publication est-il mentionné ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "L'accroche reflète-t-elle un lien entre le post et la proposition de valeur ?",
                    "key": ["accroche", "mail_context", "sender_value_proposition"]
                },
                {
                    "question": "La publication est-elle utilisée pour ouvrir un dialogue avec la personne ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "La terminologie ou les mots-clés utilisés dans le post apparaissent-ils dans l'accroche ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "L’accroche évoque-t-elle une idée nouvelle inspirée de la publication ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "L'accroche fait-elle preuve d’un effort manifeste pour relier le post au message global ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "L’accroche contient-elle des références spécifiques au profil ou au post du destinataire ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "L’accroche reprend-elle un mot-clé important du post ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "L'accroche évoque-t-elle une problématique ou une opportunité soulevée dans le post ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "L’accroche intègre-t-elle des éléments tirés du post publié par le destinataire ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "Le message fait-il ressentir que l’accroche a été rédigée spécifiquement pour cette personne ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "L’accroche mentionne-t-elle un objectif ou une intention exprimée dans la publication ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "Le timing du post est-il mentionné ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "L’accroche contient-elle une réponse explicite au post ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "Une référence à un événement ou une actualité mentionnée dans le post est-elle intégrée ?",
                    "key": ["accroche", "mail_context"]
                },
                {
                    "question": "L'accroche évite-t-elle les généralités pour s'appuyer uniquement sur les détails du post ?",
                    "key": ["accroche", "mail_context"]
                }
            ]
        }

        if (mailSegmentKey === "accroche") {
            try {
                const data = require(`./questions/${subcategory}.json`);
               
                if (data && data[subcategory] && !showQuestion) setShowQuestion(true)

                return data && data[subcategory][mailSegmentKey]
                    ? data[subcategory][mailSegmentKey]
                    : default_data;
            } catch (error) {
                console.error("Erreur lors du chargement du fichier JSON:", error);
                return default_data;
            }
        }

        const segmentData: any = {
            "transition":
            {
                "coherence": [
                    {
                        "question": "La phrase de transition fait-elle le lien naturel entre l'accroche et la proposition de valeur ?",
                        "key": [
                            "transition",
                            "sender_value_proposition",
                            "accroche"
                        ]
                    },
                    {
                        "question": "La transition relie-t-elle bien les enjeux mentionnés dans l'accroche ?",
                        "key": [
                            "transition",
                            "sender_value_proposition",
                            "accroche"
                        ]
                    },
                    {
                        "question": "La transition fait-elle en sorte que le passage à la proposition de valeur se fasse sans rupture ?",
                        "key": [
                            "transition",
                            "accroche",
                            "sender_value_proposition"
                        ]
                    },
                    {
                        "question": "La transition guide-t-elle le lecteur de manière fluide vers la proposition de valeur ?",
                        "key": [
                            "transition",
                            "sender_value_proposition"
                        ]
                    },
                    {
                        "question": "La proposition de valeur semble-t-elle être une réponse à ce qui a été évoqué dans l'accroche ?",
                        "key": [
                            "transition",
                            "sender_value_proposition",
                            "accroche"
                        ]
                    },
                    {
                        "question": "La solution proposée semble-t-elle découler logiquement de la situation introduite dans l'accroche ?",
                        "key": [
                            "transition",
                            "accroche",
                            "mail_context"
                        ]
                    },
                    {
                        "question": "La transition ne modifie-t-elle pas abruptement le style de l'accroche ?",
                        "key": [
                            "transition",
                            "accroche"
                        ]
                    },
                    {
                        "question": "La transition crée-t-elle une continuité dans l’atmosphère du message ?",
                        "key": [
                            "transition",
                            "sender_value_proposition",
                            "accroche"
                        ]
                    },
                    {
                        "question": "La proposition de valeur s'inscrit-elle de façon naturelle après l'accroche ?",
                        "key": [
                            "transition",
                            "accroche",
                            "mail_context"
                        ]
                    },
                    {
                        "question": "Les questions sont-elles pertinentes et alignées avec les préoccupations du destinataire ?",
                        "key": [
                            "transition",
                            "person_job",
                            "mail_context"
                        ]
                    },
                    {
                        "question": "Les questions encouragent-elles une réponse implicite favorable à la proposition de valeur ?",
                        "key": [
                            "transition",
                            "sender_value_proposition"
                        ]
                    },
                    {
                        "question": "Aucune information ou suggestion dans la transition ne contredit-elle les points évoqués dans l’accroche ou la proposition de valeur ?",
                        "key": [
                            "accroche",
                            "transition",
                            "sender_value_proposition"
                        ]
                    },
                    {
                        "question": "La transition guide-t-elle naturellement le lecteur vers la proposition de valeur, en restant alignée avec l’objectif de l’email ?",
                        "key": [
                            "transition",
                            "sender_value_proposition"
                        ]
                    },
                    {
                        "question": "La transition prépare-t-elle efficacement le terrain pour la proposition de valeur, sans détourner le lecteur de l’objectif principal ?",
                        "key": [
                            "transition",
                            "sender_value_proposition"
                        ]
                    },
                    {
                        "question": "La transition ne semble-t-elle pas forcée ou artificielle dans le contexte du mail ?",
                        "key": [
                            "transition",
                            "accroche",
                            "mail_context"
                        ]
                    }
                ],
                "clarity": [
                    {
                        "question": "La phrase de transition est-elle claire et directe ?",
                        "key": [
                            "transition"
                        ]
                    },
                    {
                        "question": "La phrase de transition ne contient-elle pas de termes ambigus ?",
                        "key": [
                            "transition"
                        ]
                    },
                    {
                        "question": "Le destinataire peut-il rapidement comprendre la relation entre l'accroche et la proposition de valeur ?",
                        "key": [
                            "transition",
                            "sender_value_proposition"
                        ]
                    },
                    {
                        "question": "La phrase de transition élimine-t-elle toute confusion quant à l'intention de la proposition ?",
                        "key": [
                            "transition",
                            "sender_value_proposition"
                        ]
                    },
                    {
                        "question": "La transition utilise-t-elle un langage simple et compréhensible ?",
                        "key": [
                            "transition"
                        ]
                    },
                    {
                        "question": "Les mots de la transition sont-ils choisis de manière à éviter toute complexité inutile ?",
                        "key": [
                            "transition"
                        ]
                    },
                    {
                        "question": "La transition ne surcharge-t-elle pas le message avec des informations ou des détails superflus ?",
                        "key": [
                            "transition"
                        ]
                    },
                    {
                        "question": "La phrase de transition reste-t-elle concise tout en maintenant sa clarté ?",
                        "key": [
                            "transition"
                        ]
                    },
                    {
                        "question": "La transition améliore-t-elle la compréhension générale du mail ?",
                        "key": [
                            "accroche",
                            "transition",
                            "sender_value_proposition"
                        ]
                    },
                    {
                        "question": "La transition contribue-t-elle à une meilleure compréhension du lien entre l’accroche et la proposition de valeur ?",
                        "key": [
                            "accroche",
                            "transition",
                            "sender_value_proposition"
                        ]
                    }
                ],
                "fluidity": [
                    {
                        "question": "La transition permet-elle une lecture fluide de l’email ?",
                        "key": [
                            "accroche",
                            "transition",
                            "sender_value_proposition"
                        ]
                    },
                    {
                        "question": "La phrase de transition facilite-t-elle la progression naturelle du mail ?",
                        "key": [
                            "accroche",
                            "transition",
                            "sender_value_proposition"
                        ]
                    },
                    {
                        "question": "La transition fait-elle en sorte que la lecture soit agréable ?",
                        "key": [
                            "transition"
                        ]
                    },
                    {
                        "question": "La transition facilite-t-elle un passage naturel d'une idée à l'autre ?",
                        "key": [
                            "accroche",
                            "transition",
                            "sender_value_proposition"
                        ]
                    },
                    {
                        "question": "La transition permet-elle un enchaînement naturel entre l'accroche et la proposition de valeur ?",
                        "key": [
                            "accroche",
                            "transition",
                            "sender_value_proposition"
                        ]
                    },
                    {
                        "question": "La phrase de transition assure-t-elle une continuité logique entre le problème et la solution ?",
                        "key": [
                            "accroche",
                            "transition",
                            "sender_value_proposition"
                        ]
                    },
                    {
                        "question": "La transition donne-t-elle l'impression d'une progression fluide dans le message ?",
                        "key": [
                            "accroche",
                            "transition",
                            "sender_value_proposition"
                        ]
                    },
                    {
                        "question": "Le texte ne semble-t-il pas brusquement coupé ou trop soudain après la transition ?",
                        "key": [
                            "transition",
                            "sender_value_proposition"
                        ]
                    },
                    {
                        "question": "La transition permet-elle de lier deux idées de manière cohérente et fluide ?",
                        "key": [
                            "accroche",
                            "mail_context",
                            "transition",
                            "sender_value_proposition"
                        ]
                    },
                    {
                        "question": "La transition permet-elle de maintenir une harmonie tonale entre les différentes parties du mail ?",
                        "key": [
                            "accroche",
                            "transition",
                            "sender_value_proposition"
                        ]
                    },
                    {
                        "question": "Les questions jouent-elles le rôle de pont naturel entre l’accroche et la solution proposée ?",
                        "key": [
                            "accroche",
                            "transition",
                            "sender_value_proposition"
                        ]
                    },
                    {
                        "question": "La transition inclut-elle des questions ouvertes pour capter l’attention du lecteur ?",
                        "key": [
                            "accroche",
                            "transition"
                        ]
                    },
                    {
                        "question": "L’utilisation du pronom « je » ou « nous » favorise-t-elle une connexion humaine et personnalisée ?",
                        "key": [
                            "accroche",
                            "mail_context",
                            "transition"
                        ]
                    },
                    {
                        "question": "La transition introduit-elle des questions engageantes qui stimulent la réflexion ou suscitent l’intérêt du destinataire ?",
                        "key": [
                            "transition",
                            "sender_value_proposition"
                        ]
                    },
                    {
                        "question": "La transition évite-t-elle un effet de 'copier-coller' en étant bien intégrée au texte ?",
                        "key": [
                            "accroche",
                            "mail_context",
                            "transition"
                        ]
                    }
                ]
            },
            "service_value": {
                "pertinence": [
                    {
                        "question": "La proposition de valeur fait-elle écho à un sujet mentionné dans le post ?",
                        "key": ["service_value", "person_job", "mail_context"]
                    },
                    {
                        "question": "La proposition fait-elle un lien explicite entre une publication LinkedIn récente et la solution proposée ?",
                        "key": ["accroche", "mail_context", "service_value"]
                    },
                    {
                        "question": "La proposition de valeur répond-elle à une problématique soulevée par {name} dans son post LinkedIn ?",
                        "key": ["mail_context", "service_value"]
                    },
                    {
                        "question": "La solution proposée est-elle en accord avec les idées exprimées par {name} ?",
                        "key": ["mail_context", "service_value"]
                    },
                    {
                        "question": "La proposition de valeur met-elle en avant un avantage distinctif ?",
                        "key": ["service_value"]
                    },
                    {
                        "question": "Met-elle en avant un atout distinctif que le destinataire ne trouverait nulle part ailleurs ?",
                        "key": ["service_value"]
                    },
                    {
                        "question": "Mentionne-t-elle un résultat concret ?",
                        "key": ["service_value"]
                    },
                    {
                        "question": "Permet-elle au destinataire d’anticiper un retour sur investissement clair ?",
                        "key": ["service_value"]
                    },
                    {
                        "question": "Inspire-t-elle confiance quant à l’efficacité de la solution proposée ?",
                        "key": ["service_value"]
                    },
                    {
                        "question": "La proposition met-elle en avant un bénéfice suffisamment important pour justifier un RDV ?",
                        "key": ["service_value"]
                    },
                    {
                        "question": "Le message évite-t-il les phrases génériques (comme : une solution innovante) sans explication ?",
                        "key": ["service_value"]
                    },
                    {
                        "question": "Met-il en avant un bénéfice quantifiable et mesurable ?",
                        "key": ["service_value"]
                    },
                    {
                        "question": "L’email montre-t-il pourquoi il est plus intéressant d’accepter un RDV avec cet émetteur plutôt qu’un autre ?",
                        "key": ["service_value", "sender_value_proposition"]
                    },
                    {
                        "question": "La proposition de valeur est-elle adaptée aux enjeux spécifiques du secteur {company_domain_activity} ?",
                        "key": ["service_value", "company_domain_activity"]
                    },
                    {
                        "question": "Les besoins typiques du secteur sont-ils pris en compte dans la solution proposée ?",
                        "key": ["service_value", "company_domain_activity"]
                    },
                    {
                        "question": "La proposition de valeur mentionne-t-elle une solution adaptée aux opportunités du secteur {company_domain_activity} ?",
                        "key": ["service_value", "company_domain_activity"]
                    },
                    {
                        "question": "L’email évite-t-il des généralités qui ne s’appliquent pas au domaine d’activité du destinataire ?",
                        "key": ["service_value", "company_domain_activity"]
                    },
                    {
                        "question": "La solution proposée témoigne-t-elle d’une bonne connaissance des pratiques et standards du secteur {company_domain_activity} ?",
                        "key": ["service_value", "company_domain_activity"]
                    },
                    {
                        "question": "La proposition de valeur évite-t-elle les généralités ou les affirmations vagues ?",
                        "key": ["service_value"]
                    },
                    {
                        "question": "La proposition de valeur donne-t-elle une raison forte de prendre un RDV sans attendre ?",
                        "key": ["service_value"]
                    },
                    {
                        "question": "L’email montre-t-il une compréhension des objectifs professionnels typiques liés au poste de {person_job} ?",
                        "key": ["service_value", "person_job"]
                    },
                    {
                        "question": "Les bénéfices présentés correspondent-ils aux priorités du destinataire en fonction de son poste de {person_job} ?",
                        "key": ["service_value", "person_job"]
                    },
                    {
                        "question": "La proposition de valeur reflète-t-elle une connaissance des compétences clés associées au poste de {person_job} ?",
                        "key": ["service_value", "person_job"]
                    },
                    {
                        "question": "Les avantages proposés répondent-ils aux attentes ou aux préoccupations professionnelles d’un {person_job} ?",
                        "key": ["service_value", "person_job", "mail_context"]
                    }
                ],
                "clarity": [
                    {
                        "question": "Les phrases sont-elles courtes et percutantes ?",
                        "key": ["service_value", "sender_value_proposition"]
                    },
                    {
                        "question": "Les termes utilisés sont-ils compréhensibles pour une audience non experte ?",
                        "key": ["service_value", "sender_value_proposition"]
                    },
                    {
                        "question": "Y a-t-il des expressions ambiguës ou sujettes à confusion ?",
                        "key": ["service_value"]
                    },
                    {
                        "question": "Le message peut-il être compris dès une première lecture rapide ?",
                        "key": ["service_value"]
                    },
                    {
                        "question": "Les informations secondaires ne diluent-elles pas le message principal ?",
                        "key": ["service_value", "sender_value_proposition"]
                    },
                    {
                        "question": "Les idées sont-elles présentées dans un ordre logique et fluide ?",
                        "key": ["service_value", "sender_value_proposition"]
                    },
                    {
                        "question": "Les transitions entre les différentes parties du message sont-elles naturelles ?",
                        "key": ["service_value", "sender_value_proposition"]
                    },
                    {
                        "question": "Le message évite-t-il les répétitions inutiles ?",
                        "key": ["service_value", "sender_value_proposition"]
                    },
                    {
                        "question": "Les arguments ou bénéfices principaux sont-ils clairement hiérarchisés ?",
                        "key": ["service_value", "sender_value_proposition"]
                    },
                    {
                        "question": "Le texte suit-il un schéma clair (ex : problème, solution, bénéfices) ?",
                        "key": ["service_value", "sender_value_proposition"]
                    },
                    {
                        "question": "Les informations fournies sont-elles pertinentes pour comprendre la proposition ?",
                        "key": ["service_value", "sender_value_proposition"]
                    },
                    {
                        "question": "Les détails présentés renforcent-ils clairement les bénéfices mentionnés ?",
                        "key": ["service_value", "sender_value_proposition"]
                    },
                    {
                        "question": "L’email évite-t-il les informations superflues ou hors sujet ?",
                        "key": ["service_value", "sender_value_proposition"]
                    },
                    {
                        "question": "Les exemples utilisés sont-ils précis et adaptés au rôle de {person_job} ?",
                        "key": ["service_value", "sender_value_proposition", "person_job"]
                    },
                    {
                        "question": "Le niveau de détail est-il suffisant sans être excessif ?",
                        "key": ["service_value"]
                    },
                    {
                        "question": "La proposition de valeur est-elle en cohérence avec l’accroche de l’email ?",
                        "key": ["accroche", "service_value"]
                    },
                    {
                        "question": "Les arguments ou bénéfices s’appuient-ils sur des points évoqués auparavant ?",
                        "key": ["accroche", "service_value", "sender_value_proposition"]
                    },
                    {
                        "question": "Les références éventuelles aux publications LinkedIn sont-elles bien intégrées ?",
                        "key": ["service_value", "mail_context"]
                    },
                    {
                        "question": "L’ensemble du message est-il cohérent et aligné du début à la fin ?",
                        "key": ["accroche", "transition", "service_value"]
                    },
                    {
                        "question": "La proposition de valeur est-elle visuellement distincte du reste du message ?",
                        "key": ["service_value", "sender_value_proposition"]
                    },
                    {
                        "question": "Les phrases et listes sont-elles correctement espacées pour une lecture fluide ?",
                        "key": ["service_value"]
                    },
                    {
                        "question": "Le formatage (paragraphes, ponctuation) facilite-t-il une lecture rapide ?",
                        "key": ["service_value", "sender_value_proposition"]
                    },
                    {
                        "question": "L’objectif final du message est-il clair du début à la fin ?",
                        "key": ["accroche", "mail_context", "service_value"]
                    },
                    {
                        "question": "Le ton employé est-il naturel et conversationnel ?",
                        "key": ["service_value"]
                    },
                    {
                        "question": "Les répétitions inutiles sont-elles évitées ?",
                        "key": ["service_value"]
                    }
                ],
                "emotional_impact": [
                    {
                        "question": "La proposition s’adresse-t-elle à un besoin pour un {person_job} ?",
                        "key": ["service_value", "person_job"]
                    },
                    {
                        "question": "La proposition de valeur fait-elle appel à des émotions positives ?",
                        "key": ["service_value"]
                    },
                    {
                        "question": "Les bénéfices évoqués dans la proposition sont-ils en phase avec les motivations du lecteur (exemple : gain de temps, sécurité, objectif, efficacité) ?",
                        "key": ["service_value"]
                    },
                    {
                        "question": "La proposition de valeur évoque-t-elle des termes ou concepts pertinents pour les {company_domain_activity} ?",
                        "key": ["mail_context", "service_value"]
                    },
                    {
                        "question": "Le langage utilisé est-il engageant et évocateur ?",
                        "key": ["service_value"]
                    },
                    {
                        "question": "La proposition contient-elle des mots qui inspirent confiance ?",
                        "key": ["service_value", "sender_value_proposition"]
                    },
                    {
                        "question": "Les phrases en général sont-elles chaleureuses ?",
                        "key": ["service_value"]
                    },
                    {
                        "question": "La proposition s’adresse-t-elle directement à {name} ?",
                        "key": ["service_value"]
                    },
                    {
                        "question": "Le contenu donne-t-il l’impression que l’émetteur comprend les besoins spécifiques du destinataire ?",
                        "key": ["service_value", "mail_context", "person_job", "sender_value_proposition"]
                    },
                    {
                        "question": "Y a-t-il une tentative de personnalisation émotionnelle ?",
                        "key": ["service_value", "person_job", "mail_context", "sender_value_proposition"]
                    },
                    {
                        "question": "L’approche semble-t-elle sincère et authentique dans sa manière de se connecter au lecteur ?",
                        "key": ["service_value"]
                    },
                    {
                        "question": "Le message montre-t-il que l’émetteur accorde une importance particulière aux valeurs ou attentes du destinataire ?",
                        "key": ["service_value", "company_value", "person_job", "sender_value_proposition", "mail_context"]
                    },
                    {
                        "question": "Les bénéfices évoqués sont-ils présentés de manière à stimuler le désir de passer à l’action ?",
                        "key": ["service_value", "sender_value_proposition"]
                    },
                    {
                        "question": "Le message met-il en avant un sentiment d’urgence ou d’opportunité ?",
                        "key": ["service_value", "sender_value_proposition"]
                    },
                    {
                        "question": "Les bénéfices évoqués semblent-ils réalistes et atteignables, sans exagération ?",
                        "key": ["service_value", "sender_value_proposition"]
                    },
                    {
                        "question": "Le texte contient-il des éléments de réassurance ?",
                        "key": ["service_value", "sender_value_proposition"]
                    },
                    {
                        "question": "L’émetteur évoque-t-il des réussites ou résultats concrets pour renforcer la crédibilité ?",
                        "key": ["service_value", "sender_value_proposition"]
                    },
                    {
                        "question": "La manière de formuler la proposition inspire-t-elle confiance et professionnalisme ?",
                        "key": ["service_value", "sender_value_proposition"]
                    },
                    {
                        "question": "Le vocabulaire utilisé est-il vivant et expressif plutôt que générique et plat ?",
                        "key": ["service_value", "sender_value_proposition"]
                    },
                    {
                        "question": "Les adjectifs utilisés soulignent-ils les bénéfices de manière efficace et percutante ?",
                        "key": ["service_value", "sender_value_proposition"]
                    },
                    {
                        "question": "L’email donne-t-il au lecteur l’impression d’être traité comme un individu unique ?",
                        "key": ["service_value", "sender_value_proposition"]
                    },
                    {
                        "question": "Le message met-il en avant les conséquences positives d’une action immédiate ?",
                        "key": ["service_value", "sender_value_proposition"]
                    },
                    {
                        "question": "Le message donne-t-il au lecteur une bonne raison de répondre rapidement ?",
                        "key": ["service_value", "sender_value_proposition"]
                    },
                    {
                        "question": "Le message contient-il une preuve de valeur ou un témoignage ?",
                        "key": ["service_value", "sender_value_proposition"]
                    },
                    {
                        "question": "Les engagements ou garanties évoqués sont-ils clairs et crédibles ?",
                        "key": ["service_value", "sender_value_proposition"]
                    }
                ],
                "personalization": [
                    {
                        "question": "La proposition mentionne-t-elle un besoin spécifique du destinataire ?",
                        "key": ["service_value", "sender_value_proposition", "person_job"]
                    },
                    {
                        "question": "L’offre est-elle alignée avec les objectifs principaux du destinataire ou de {company} ?",
                        "key": ["service_value", "sender_value_proposition", "company_value"]
                    },
                    {
                        "question": "La proposition montre-t-elle que l’émetteur a fait des recherches sur {name} ou {company} ?",
                        "key": ["service_value", "company_domain_activity"]
                    },
                    {
                        "question": "Les solutions proposées sont-elles pertinentes pour le domaine de {company_domain_activity} ?",
                        "key": ["service_value", "sender_value_proposition", "company_domain_activity"]
                    },
                    {
                        "question": "La proposition fait-elle référence à des éléments propres au {person_job} ou aux responsabilités de {name} ?",
                        "key": ["service_value", "sender_value_proposition", "person_job"]
                    },
                    {
                        "question": "Les besoins liés à la taille ou au modèle économique de {company} sont-ils pris en compte ?",
                        "key": ["service_value", "company_domain_activity", "company"]
                    },
                    {
                        "question": "L’émetteur montre-t-il qu’il comprend les processus ou les structures internes de l’entreprise ?",
                        "key": ["service_value", "sender_value_proposition", "company_domain_activity"]
                    },
                    {
                        "question": "La proposition fait-elle référence à des points de douleur ou des opportunités spécifiques à l’industrie de {company} ?",
                        "key": ["service_value", "sender_value_proposition", "company_domain_activity"]
                    },
                    {
                        "question": "La proposition de valeur est-elle formulée pour répondre à des défis ou des besoins d'entreprise spécifiques ?",
                        "key": ["service_value", "sender_value_proposition", "company_value"]
                    },
                    {
                        "question": "La solution proposée s’ajuste-t-elle aux priorités de l’entreprise du destinataire ?",
                        "key": ["service_value", "sender_value_proposition", "company_value"]
                    },
                    {
                        "question": "La proposition de valeur est-elle ajustée en fonction du rôle ou du poste du destinataire ?",
                        "key": ["service_value", "sender_value_proposition", "person_job"]
                    },
                    {
                        "question": "Le contenu du message reflète-t-il une compréhension du rôle du destinataire dans l’entreprise ?",
                        "key": ["service_value", "sender_value_proposition", "person_job"]
                    },
                    {
                        "question": "La proposition de valeur s'adresse-t-elle spécifiquement aux besoins liés au poste du destinataire ?",
                        "key": ["service_value", "sender_value_proposition", "person_job"]
                    },
                    {
                        "question": "L'email personnalise-t-il la solution en fonction des priorités stratégiques du destinataire ?",
                        "key": ["service_value", "sender_value_proposition", "company_value"]
                    },
                    {
                        "question": "Des publications ou partages récents du destinataire sont-ils intégrés dans la proposition ?",
                        "key": ["service_value", "sender_value_proposition", "mail_context"]
                    },
                    {
                        "question": "La proposition inclut-elle une reconnaissance des actions ou décisions prises récemment par le destinataire ?",
                        "key": ["service_value", "sender_value_proposition", "mail_context"]
                    },
                    {
                        "question": "Les exemples ou illustrations utilisés sont-ils pertinents par rapport aux intérêts personnels ou professionnels du destinataire ?",
                        "key": ["service_value", "sender_value_proposition", "person_job"]
                    },
                    {
                        "question": "La proposition évoque-t-elle un partenariat ou une collaboration possible basé sur des intérêts communs ?",
                        "key": ["service_value", "sender_value_proposition"]
                    },
                    {
                        "question": "Le message mentionne-t-il des outils ou méthodes spécifiques utilisés par le destinataire ou son entreprise ?",
                        "key": ["service_value", "sender_value_proposition", "company_domain_activity"]
                    },
                    {
                        "question": "Le niveau de détail est-il suffisant pour montrer une compréhension approfondie des besoins du destinataire ?",
                        "key": ["service_value", "sender_value_proposition"]
                    },
                    {
                        "question": "La proposition de valeur inclut-elle des termes ou expressions propres au secteur d’activité du destinataire ?",
                        "key": ["service_value", "sender_value_proposition", "company_domain_activity"]
                    },
                    {
                        "question": "La personnalisation semble-t-elle authentique et non générique ?",
                        "key": ["service_value", "sender_value_proposition"]
                    },
                    {
                        "question": "Le message évite-t-il les phrases standardisées ou les formulations trop générales ?",
                        "key": ["service_value", "sender_value_proposition"]
                    },
                    {
                        "question": "L’émetteur montre-t-il un intérêt sincère pour les besoins du destinataire ?",
                        "key": ["service_value", "sender_value_proposition"]
                    },
                    {
                        "question": "Les arguments présentés semblent-ils uniques et spécialement conçus pour ce destinataire ?",
                        "key": ["service_value", "sender_value_proposition"]
                    }
                ]
            }
        }

        return segmentData[mailSegmentKey];
    };

    const questionsByCriteria2 = (mailSegmentKey: string): QuestionList => {
        switch (mailSegmentKey) {
            case "accroche":
                return {
                    "pertinence": [
                        {
                            "question": "L’accroche mentionne-t-elle le post que la personne a publié sur LinkedIn ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche mentionne-t-elle un sujet abordé dans le post du destinataire ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche mentionne-t-elle un événement ou une activité précisée dans le post ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche reprend-elle des mots-clés du post, tels que {Context_keyword} ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L'accroche mentionne-t-elle un produit ou une solution spécifique ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche reformule-t-elle l’idée principale du post ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche mentionne-t-elle un événement auquel {name} a assisté ou auquel il/elle participera ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche fait-elle allusion à une priorité pour {name} ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche utilise-t-elle des informations tirées du post LinkedIn ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L'accroche respecte-t-elle le sujet principal du post LinkedIn ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L'accroche met-elle en avant un défi, une opportunité ou un succès mentionné dans le post ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche inclut-elle un élément en lien avec son poste ou son entreprise ?",
                            "key": ["accroche", "person_job", "mail_context"]
                        },
                        {
                            "question": "L'accroche met-elle en avant un aspect positif ou un succès présenté dans le post de {name} ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche contient-elle un acronyme présent dans le post ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche mentionne-t-elle {company} ?",
                            "key": ["accroche", "company"]
                        },
                        {
                            "question": "L’accroche utilise-t-elle les termes liés à {company_domain_activity} ?",
                            "key": ["accroche", "company_domain_activity"]
                        },
                        {
                            "question": "Le domaine d’activité est-il utilisé pour établir une connexion avec le destinataire ?",
                            "key": ["accroche", "company_domain_activity"]
                        },
                        {
                            "question": "L’accroche évoque-t-elle des innovations pertinentes pour le secteur {company_domain_activity} ?",
                            "key": ["accroche", "company_domain_activity"]
                        },
                        {
                            "question": "L'accroche traite-t-elle de l’utilisation de l’IA dans le domaine de {company_domain_activity} ?",
                            "key": ["accroche", "company_domain_activity"]
                        },
                        {
                            "question": "L’accroche fait-elle référence à un projet particulier de {name} ?",
                            "key": ["accroche", "mail_context", "person_job"]
                        },
                        {
                            "question": "L’accroche semble-t-elle adaptée pour un {person_job} ?",
                            "key": ["accroche", "mail_context", "person_job"]
                        },
                        {
                            "question": "L'accroche évoque-t-elle des objectifs pour un {person_job} (tels que Croissance, Performance, Stratégie, Innovation, Résultats) ?",
                            "key": ["accroche", "mail_context", "person_job"]
                        },
                        {
                            "question": "L'accroche mentionne-t-elle des défis liés au rôle de {person_job} (tels que objectifs, motivation, marché, compétences et innovation) ?",
                            "key": ["accroche", "mail_context", "person_job"]
                        },
                        {
                            "question": "L'accroche reflète-t-elle les responsabilités de {name} en tant que {person_job} ?",
                            "key": ["accroche", "mail_context", "person_job"]
                        },
                        {
                            "question": "L’accroche donne-t-elle une raison claire pour que {name} se sente personnellement concerné(e) ?",
                            "key": ["accroche", "person_job", "mail_context"]
                        }
                    ],
                    "clarity": [
                        {
                            "question": "L’accroche fait-elle moins de 35 mots ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "Le vocabulaire utilisé est-il compréhensible par le destinataire sans ambiguïté ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L’accroche évite-t-elle les termes trop techniques ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "La phrase est-elle facile à comprendre ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L’accroche utilise-t-elle des mots simples et directs ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "Les phrases de l’accroche s’enchaînent-elles logiquement ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L’accroche suit-elle une progression logique (sujet-verbe-complément) ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "Aucune contradiction n’est-elle présente dans les phrases de l’accroche ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L’accroche évite-t-elle des idées vagues ou des affirmations floues ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "Les phrases restent-elles centrées sur le destinataire ?",
                            "key": ["accroche", "person_job", "mail_context"]
                        },
                        {
                            "question": "L’accroche va-t-elle droit au but ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "Les éléments mentionnés dans l’accroche sont-ils précis ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L’accroche cite-t-elle des termes spécifiques pour un {person_job} ou au {company_domain_activity} ?",
                            "key": ["accroche", "mail_context", "company_domain_activity", "person_job"]
                        },
                        {
                            "question": "Aucune phrase de l’accroche n’est-elle abstraite ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L’accroche contient-elle uniquement les informations essentielles ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche commence-t-elle par une phrase claire ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L’accroche suit-elle une structure logique et fluide ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "Les idées principales sont-elles présentées dès le début de l’accroche ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "Les mots-clés comme {Context_keyword} ou éléments importants apparaissent-ils au début de chaque phrase ?",
                            "key": ["accroche", "mail_context", "company_value"]
                        },
                        {
                            "question": "Aucune partie de l’accroche n’est-elle répétitive ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L’accroche évite-t-elle les acronymes ou abréviations non expliquées ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L’accroche évite-t-elle les répétitions inutiles ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "La construction grammaticale est-elle correcte et fluide ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L’accroche présente-t-elle les informations de manière concise et sans surcharge ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "Les mots utilisés sont-ils précis et évitent-ils les généralités ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche reste-t-elle claire tout en étant personnalisée ?",
                            "key": ["accroche", "mail_context", "person_job"]
                        }
                    ],
                    "captivant": [
                        {
                            "question": "L’accroche suscite-t-elle la curiosité en faisant un lien avec un sujet du destinataire ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche donne-t-elle envie d’en savoir plus sur le sujet abordé ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L'accroche donne-t-elle l'impression qu'il y a quelque chose d'important à découvrir ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L’accroche donne-t-elle l’impression d’un sujet inédit ou novateur ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche met-elle en avant un défi ou un problème à résoudre ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L’accroche mentionne-t-elle un changement ou une nouveauté ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L'accroche commence-t-elle avec une phrase qui intrigue ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L’accroche mentionne-t-elle un événement pertinent par rapport à la proposition de valeur ?",
                            "key": ["accroche", "mail_context", "sender_value_proposition"]
                        },
                        {
                            "question": "L’accroche fait-elle un lien explicite avec le contexte du destinataire ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L'accroche reflète-t-elle une compréhension des défis du destinataire ?",
                            "key": ["accroche", "person_job"]
                        },
                        {
                            "question": "L'accroche donne-t-elle l'impression d'un message personnalisé pour le destinataire ?",
                            "key": ["accroche", "mail_context", "person_job"]
                        },
                        {
                            "question": "L'accroche contient-elle des éléments spécifiques au destinataire qui la différencient d’un message générique ?",
                            "key": ["accroche", "mail_context", "person_job"]
                        },
                        {
                            "question": "L'accroche démontre-t-elle un intérêt pour le destinataire ?",
                            "key": ["accroche", "person_job", "mail_context"]
                        },
                        {
                            "question": "L'accroche donne-t-elle l'impression que l'émetteur connaît bien le contexte du destinataire ?",
                            "key": ["accroche", "person_job", "mail_context"]
                        },
                        {
                            "question": "L'accroche fait-elle sentir au destinataire qu'il est important pour l'émetteur ?",
                            "key": ["accroche", "person_job", "mail_context"]
                        },
                        {
                            "question": "L’accroche adopte-t-elle une approche différente des standards des emails commerciaux ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L’accroche utilise-t-elle un ton, un style ou une structure inhabituels ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L’accroche évite-t-elle des phrases trop commerciales ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L'accroche utilise-t-elle une métaphore ou une image frappante pour se différencier ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L’accroche contient-elle des mots émotionnels forts ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L'accroche montre-t-elle une attention particulière aux besoins ou aux intérêts du destinataire ?",
                            "key": ["accroche", "person_job"]
                        },
                        {
                            "question": "L'accroche reflète-t-elle une compréhension approfondie du secteur d’activité du destinataire ?",
                            "key": ["accroche", "company_domain_activity"]
                        },
                        {
                            "question": "L'accroche montre-t-elle que l'émetteur se soucie des objectifs du destinataire ?",
                            "key": ["accroche", "person_job", "mail_context"]
                        },
                        {
                            "question": "L'accroche propose-t-elle quelque chose de concret, sans être trop vague ou générique ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L'accroche fait-elle sentir au destinataire qu'il/elle est au centre de l'attention ?",
                            "key": ["accroche", "person_job", "mail_context"]
                        }
                    ],
                    "personalization": [
                        {
                            "question": "La publication LinkedIn de la personne est-elle mentionnée explicitement dans l'accroche ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "Une phrase spécifique liée au contenu de la publication est-elle utilisée dans l'accroche ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L'accroche reflète-t-elle les idées principales du post que la personne a publié ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L'accroche est-elle en lien avec une compétence ou une expertise spécifique du destinataire ?",
                            "key": ["accroche", "mail_context", "person_job"]
                        },
                        {
                            "question": "L'accroche mentionne-t-elle un élément qui montre que vous avez lu le contenu en détail ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "Y a-t-il une question ou une réflexion en lien direct avec la publication ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "Le sujet de la publication est-il lié à la proposition de valeur de l’email ?",
                            "key": ["accroche", "mail_context", "sender_value_proposition"]
                        },
                        {
                            "question": "Une expression ou un style spécifique au post est-il repris ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L'accroche montre-t-elle un alignement avec l’opinion de la personne dans sa publication ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "Une date ou un contexte spécifique lié à la publication est-il mentionné ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L'accroche reflète-t-elle un lien entre le post et la proposition de valeur ?",
                            "key": ["accroche", "mail_context", "sender_value_proposition"]
                        },
                        {
                            "question": "La publication est-elle utilisée pour ouvrir un dialogue avec la personne ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "La terminologie ou les mots-clés utilisés dans le post apparaissent-ils dans l'accroche ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche évoque-t-elle une idée nouvelle inspirée de la publication ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L'accroche fait-elle preuve d’un effort manifeste pour relier le post au message global ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche contient-elle des références spécifiques au profil ou au post du destinataire ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche reprend-elle un mot-clé important du post ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L'accroche évoque-t-elle une problématique ou une opportunité soulevée dans le post ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche intègre-t-elle des éléments tirés du post publié par le destinataire ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "Le message fait-il ressentir que l’accroche a été rédigée spécifiquement pour cette personne ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche mentionne-t-elle un objectif ou une intention exprimée dans la publication ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "Le timing du post est-il mentionné ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche contient-elle une réponse explicite au post ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "Une référence à un événement ou une actualité mentionnée dans le post est-elle intégrée ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L'accroche évite-t-elle les généralités pour s'appuyer uniquement sur les détails du post ?",
                            "key": ["accroche", "mail_context"]
                        }
                    ]
                }
            case "transition":
                return {
                    "coherence": [
                        {
                            "question": "La phrase de transition fait-elle le lien naturel entre l'accroche et la proposition de valeur ?",
                            "key": [
                                "transition",
                                "sender_value_proposition",
                                "accroche"
                            ]
                        },
                        {
                            "question": "La transition relie-t-elle bien les enjeux mentionnés dans l'accroche ?",
                            "key": [
                                "transition",
                                "sender_value_proposition",
                                "accroche"
                            ]
                        },
                        {
                            "question": "La transition fait-elle en sorte que le passage à la proposition de valeur se fasse sans rupture ?",
                            "key": [
                                "transition",
                                "accroche",
                                "sender_value_proposition"
                            ]
                        },
                        {
                            "question": "La transition guide-t-elle le lecteur de manière fluide vers la proposition de valeur ?",
                            "key": [
                                "transition",
                                "sender_value_proposition"
                            ]
                        },
                        {
                            "question": "La proposition de valeur semble-t-elle être une réponse à ce qui a été évoqué dans l'accroche ?",
                            "key": [
                                "transition",
                                "sender_value_proposition",
                                "accroche"
                            ]
                        },
                        {
                            "question": "La solution proposée semble-t-elle découler logiquement de la situation introduite dans l'accroche ?",
                            "key": [
                                "transition",
                                "accroche",
                                "mail_context"
                            ]
                        },
                        {
                            "question": "La transition ne modifie-t-elle pas abruptement le style de l'accroche ?",
                            "key": [
                                "transition",
                                "accroche"
                            ]
                        },
                        {
                            "question": "La transition crée-t-elle une continuité dans l’atmosphère du message ?",
                            "key": [
                                "transition",
                                "sender_value_proposition",
                                "accroche"
                            ]
                        },
                        {
                            "question": "La proposition de valeur s'inscrit-elle de façon naturelle après l'accroche ?",
                            "key": [
                                "transition",
                                "accroche",
                                "mail_context"
                            ]
                        },
                        {
                            "question": "Les questions sont-elles pertinentes et alignées avec les préoccupations du destinataire ?",
                            "key": [
                                "transition",
                                "person_job",
                                "mail_context"
                            ]
                        },
                        {
                            "question": "Les questions encouragent-elles une réponse implicite favorable à la proposition de valeur ?",
                            "key": [
                                "transition",
                                "sender_value_proposition"
                            ]
                        },
                        {
                            "question": "Aucune information ou suggestion dans la transition ne contredit-elle les points évoqués dans l’accroche ou la proposition de valeur ?",
                            "key": [
                                "accroche",
                                "transition",
                                "sender_value_proposition"
                            ]
                        },
                        {
                            "question": "La transition guide-t-elle naturellement le lecteur vers la proposition de valeur, en restant alignée avec l’objectif de l’email ?",
                            "key": [
                                "transition",
                                "sender_value_proposition"
                            ]
                        },
                        {
                            "question": "La transition prépare-t-elle efficacement le terrain pour la proposition de valeur, sans détourner le lecteur de l’objectif principal ?",
                            "key": [
                                "transition",
                                "sender_value_proposition"
                            ]
                        },
                        {
                            "question": "La transition ne semble-t-elle pas forcée ou artificielle dans le contexte du mail ?",
                            "key": [
                                "transition",
                                "accroche",
                                "mail_context"
                            ]
                        }
                    ],
                    "clarity": [
                        {
                            "question": "La phrase de transition est-elle claire et directe ?",
                            "key": [
                                "transition"
                            ]
                        },
                        {
                            "question": "La phrase de transition ne contient-elle pas de termes ambigus ?",
                            "key": [
                                "transition"
                            ]
                        },
                        {
                            "question": "Le destinataire peut-il rapidement comprendre la relation entre l'accroche et la proposition de valeur ?",
                            "key": [
                                "transition",
                                "sender_value_proposition"
                            ]
                        },
                        {
                            "question": "La phrase de transition élimine-t-elle toute confusion quant à l'intention de la proposition ?",
                            "key": [
                                "transition",
                                "sender_value_proposition"
                            ]
                        },
                        {
                            "question": "La transition utilise-t-elle un langage simple et compréhensible ?",
                            "key": [
                                "transition"
                            ]
                        },
                        {
                            "question": "Les mots de la transition sont-ils choisis de manière à éviter toute complexité inutile ?",
                            "key": [
                                "transition"
                            ]
                        },
                        {
                            "question": "La transition ne surcharge-t-elle pas le message avec des informations ou des détails superflus ?",
                            "key": [
                                "transition"
                            ]
                        },
                        {
                            "question": "La phrase de transition reste-t-elle concise tout en maintenant sa clarté ?",
                            "key": [
                                "transition"
                            ]
                        },
                        {
                            "question": "La transition améliore-t-elle la compréhension générale du mail ?",
                            "key": [
                                "accroche",
                                "transition",
                                "sender_value_proposition"
                            ]
                        },
                        {
                            "question": "La transition contribue-t-elle à une meilleure compréhension du lien entre l’accroche et la proposition de valeur ?",
                            "key": [
                                "accroche",
                                "transition",
                                "sender_value_proposition"
                            ]
                        }
                    ],
                    "fluidity": [
                        {
                            "question": "La transition permet-elle une lecture fluide de l’email ?",
                            "key": [
                                "accroche",
                                "transition",
                                "sender_value_proposition"
                            ]
                        },
                        {
                            "question": "La phrase de transition facilite-t-elle la progression naturelle du mail ?",
                            "key": [
                                "accroche",
                                "transition",
                                "sender_value_proposition"
                            ]
                        },
                        {
                            "question": "La transition fait-elle en sorte que la lecture soit agréable ?",
                            "key": [
                                "transition"
                            ]
                        },
                        {
                            "question": "La transition facilite-t-elle un passage naturel d'une idée à l'autre ?",
                            "key": [
                                "accroche",
                                "transition",
                                "sender_value_proposition"
                            ]
                        },
                        {
                            "question": "La transition permet-elle un enchaînement naturel entre l'accroche et la proposition de valeur ?",
                            "key": [
                                "accroche",
                                "transition",
                                "sender_value_proposition"
                            ]
                        },
                        {
                            "question": "La phrase de transition assure-t-elle une continuité logique entre le problème et la solution ?",
                            "key": [
                                "accroche",
                                "transition",
                                "sender_value_proposition"
                            ]
                        },
                        {
                            "question": "La transition donne-t-elle l'impression d'une progression fluide dans le message ?",
                            "key": [
                                "accroche",
                                "transition",
                                "sender_value_proposition"
                            ]
                        },
                        {
                            "question": "Le texte ne semble-t-il pas brusquement coupé ou trop soudain après la transition ?",
                            "key": [
                                "transition",
                                "sender_value_proposition"
                            ]
                        },
                        {
                            "question": "La transition permet-elle de lier deux idées de manière cohérente et fluide ?",
                            "key": [
                                "accroche",
                                "mail_context",
                                "transition",
                                "sender_value_proposition"
                            ]
                        },
                        {
                            "question": "La transition permet-elle de maintenir une harmonie tonale entre les différentes parties du mail ?",
                            "key": [
                                "accroche",
                                "transition",
                                "sender_value_proposition"
                            ]
                        },
                        {
                            "question": "Les questions jouent-elles le rôle de pont naturel entre l’accroche et la solution proposée ?",
                            "key": [
                                "accroche",
                                "transition",
                                "sender_value_proposition"
                            ]
                        },
                        {
                            "question": "La transition inclut-elle des questions ouvertes pour capter l’attention du lecteur ?",
                            "key": [
                                "accroche",
                                "transition"
                            ]
                        },
                        {
                            "question": "L’utilisation du pronom « je » ou « nous » favorise-t-elle une connexion humaine et personnalisée ?",
                            "key": [
                                "accroche",
                                "mail_context",
                                "transition"
                            ]
                        },
                        {
                            "question": "La transition introduit-elle des questions engageantes qui stimulent la réflexion ou suscitent l’intérêt du destinataire ?",
                            "key": [
                                "transition",
                                "sender_value_proposition"
                            ]
                        },
                        {
                            "question": "La transition évite-t-elle un effet de 'copier-coller' en étant bien intégrée au texte ?",
                            "key": [
                                "accroche",
                                "mail_context",
                                "transition"
                            ]
                        }
                    ]
                }
            case "service_value":
                return {
                    "pertinence": [
                        {
                            "question": "La proposition de valeur fait-elle écho à un sujet mentionné dans le post ?",
                            "key": ["service_value", "person_job", "mail_context"]
                        },
                        {
                            "question": "La proposition fait-elle un lien explicite entre une publication LinkedIn récente et la solution proposée ?",
                            "key": ["accroche", "mail_context", "service_value"]
                        },
                        {
                            "question": "La proposition de valeur répond-elle à une problématique soulevée par {name} dans son post LinkedIn ?",
                            "key": ["mail_context", "service_value"]
                        },
                        {
                            "question": "La solution proposée est-elle en accord avec les idées exprimées par {name} ?",
                            "key": ["mail_context", "service_value"]
                        },
                        {
                            "question": "La proposition de valeur met-elle en avant un avantage distinctif ?",
                            "key": ["service_value"]
                        },
                        {
                            "question": "Met-elle en avant un atout distinctif que le destinataire ne trouverait nulle part ailleurs ?",
                            "key": ["service_value"]
                        },
                        {
                            "question": "Mentionne-t-elle un résultat concret ?",
                            "key": ["service_value"]
                        },
                        {
                            "question": "Permet-elle au destinataire d’anticiper un retour sur investissement clair ?",
                            "key": ["service_value"]
                        },
                        {
                            "question": "Inspire-t-elle confiance quant à l’efficacité de la solution proposée ?",
                            "key": ["service_value"]
                        },
                        {
                            "question": "La proposition met-elle en avant un bénéfice suffisamment important pour justifier un RDV ?",
                            "key": ["service_value"]
                        },
                        {
                            "question": "Le message évite-t-il les phrases génériques (comme : une solution innovante) sans explication ?",
                            "key": ["service_value"]
                        },
                        {
                            "question": "Met-il en avant un bénéfice quantifiable et mesurable ?",
                            "key": ["service_value"]
                        },
                        {
                            "question": "L’email montre-t-il pourquoi il est plus intéressant d’accepter un RDV avec cet émetteur plutôt qu’un autre ?",
                            "key": ["service_value", "sender_value_proposition"]
                        },
                        {
                            "question": "La proposition de valeur est-elle adaptée aux enjeux spécifiques du secteur {company_domain_activity} ?",
                            "key": ["service_value", "company_domain_activity"]
                        },
                        {
                            "question": "Les besoins typiques du secteur sont-ils pris en compte dans la solution proposée ?",
                            "key": ["service_value", "company_domain_activity"]
                        },
                        {
                            "question": "La proposition de valeur mentionne-t-elle une solution adaptée aux opportunités du secteur {company_domain_activity} ?",
                            "key": ["service_value", "company_domain_activity"]
                        },
                        {
                            "question": "L’email évite-t-il des généralités qui ne s’appliquent pas au domaine d’activité du destinataire ?",
                            "key": ["service_value", "company_domain_activity"]
                        },
                        {
                            "question": "La solution proposée témoigne-t-elle d’une bonne connaissance des pratiques et standards du secteur {company_domain_activity} ?",
                            "key": ["service_value", "company_domain_activity"]
                        },
                        {
                            "question": "La proposition de valeur évite-t-elle les généralités ou les affirmations vagues ?",
                            "key": ["service_value"]
                        },
                        {
                            "question": "La proposition de valeur donne-t-elle une raison forte de prendre un RDV sans attendre ?",
                            "key": ["service_value"]
                        },
                        {
                            "question": "L’email montre-t-il une compréhension des objectifs professionnels typiques liés au poste de {person_job} ?",
                            "key": ["service_value", "person_job"]
                        },
                        {
                            "question": "Les bénéfices présentés correspondent-ils aux priorités du destinataire en fonction de son poste de {person_job} ?",
                            "key": ["service_value", "person_job"]
                        },
                        {
                            "question": "La proposition de valeur reflète-t-elle une connaissance des compétences clés associées au poste de {person_job} ?",
                            "key": ["service_value", "person_job"]
                        },
                        {
                            "question": "Les avantages proposés répondent-ils aux attentes ou aux préoccupations professionnelles d’un {person_job} ?",
                            "key": ["service_value", "person_job", "mail_context"]
                        }
                    ],
                    "clarity": [
                        {
                            "question": "Les phrases sont-elles courtes et percutantes ?",
                            "key": ["service_value", "sender_value_proposition"]
                        },
                        {
                            "question": "Les termes utilisés sont-ils compréhensibles pour une audience non experte ?",
                            "key": ["service_value", "sender_value_proposition"]
                        },
                        {
                            "question": "Y a-t-il des expressions ambiguës ou sujettes à confusion ?",
                            "key": ["service_value"]
                        },
                        {
                            "question": "Le message peut-il être compris dès une première lecture rapide ?",
                            "key": ["service_value"]
                        },
                        {
                            "question": "Les informations secondaires ne diluent-elles pas le message principal ?",
                            "key": ["service_value", "sender_value_proposition"]
                        },
                        {
                            "question": "Les idées sont-elles présentées dans un ordre logique et fluide ?",
                            "key": ["service_value", "sender_value_proposition"]
                        },
                        {
                            "question": "Les transitions entre les différentes parties du message sont-elles naturelles ?",
                            "key": ["service_value", "sender_value_proposition"]
                        },
                        {
                            "question": "Le message évite-t-il les répétitions inutiles ?",
                            "key": ["service_value", "sender_value_proposition"]
                        },
                        {
                            "question": "Les arguments ou bénéfices principaux sont-ils clairement hiérarchisés ?",
                            "key": ["service_value", "sender_value_proposition"]
                        },
                        {
                            "question": "Le texte suit-il un schéma clair (ex : problème, solution, bénéfices) ?",
                            "key": ["service_value", "sender_value_proposition"]
                        },
                        {
                            "question": "Les informations fournies sont-elles pertinentes pour comprendre la proposition ?",
                            "key": ["service_value", "sender_value_proposition"]
                        },
                        {
                            "question": "Les détails présentés renforcent-ils clairement les bénéfices mentionnés ?",
                            "key": ["service_value", "sender_value_proposition"]
                        },
                        {
                            "question": "L’email évite-t-il les informations superflues ou hors sujet ?",
                            "key": ["service_value", "sender_value_proposition"]
                        },
                        {
                            "question": "Les exemples utilisés sont-ils précis et adaptés au rôle de {person_job} ?",
                            "key": ["service_value", "sender_value_proposition", "person_job"]
                        },
                        {
                            "question": "Le niveau de détail est-il suffisant sans être excessif ?",
                            "key": ["service_value"]
                        },
                        {
                            "question": "La proposition de valeur est-elle en cohérence avec l’accroche de l’email ?",
                            "key": ["accroche", "service_value"]
                        },
                        {
                            "question": "Les arguments ou bénéfices s’appuient-ils sur des points évoqués auparavant ?",
                            "key": ["accroche", "service_value", "sender_value_proposition"]
                        },
                        {
                            "question": "Les références éventuelles aux publications LinkedIn sont-elles bien intégrées ?",
                            "key": ["service_value", "mail_context"]
                        },
                        {
                            "question": "L’ensemble du message est-il cohérent et aligné du début à la fin ?",
                            "key": ["accroche", "transition", "service_value"]
                        },
                        {
                            "question": "La proposition de valeur est-elle visuellement distincte du reste du message ?",
                            "key": ["service_value", "sender_value_proposition"]
                        },
                        {
                            "question": "Les phrases et listes sont-elles correctement espacées pour une lecture fluide ?",
                            "key": ["service_value"]
                        },
                        {
                            "question": "Le formatage (paragraphes, ponctuation) facilite-t-il une lecture rapide ?",
                            "key": ["service_value", "sender_value_proposition"]
                        },
                        {
                            "question": "L’objectif final du message est-il clair du début à la fin ?",
                            "key": ["accroche", "mail_context", "service_value"]
                        },
                        {
                            "question": "Le ton employé est-il naturel et conversationnel ?",
                            "key": ["service_value"]
                        },
                        {
                            "question": "Les répétitions inutiles sont-elles évitées ?",
                            "key": ["service_value"]
                        }
                    ],
                    "emotional_impact": [
                        {
                            "question": "La proposition s’adresse-t-elle à un besoin pour un {person_job} ?",
                            "key": ["service_value", "person_job"]
                        },
                        {
                            "question": "La proposition de valeur fait-elle appel à des émotions positives ?",
                            "key": ["service_value"]
                        },
                        {
                            "question": "Les bénéfices évoqués dans la proposition sont-ils en phase avec les motivations du lecteur (exemple : gain de temps, sécurité, objectif, efficacité) ?",
                            "key": ["service_value"]
                        },
                        {
                            "question": "La proposition de valeur évoque-t-elle des termes ou concepts pertinents pour les {company_domain_activity} ?",
                            "key": ["mail_context", "service_value"]
                        },
                        {
                            "question": "Le langage utilisé est-il engageant et évocateur ?",
                            "key": ["service_value"]
                        },
                        {
                            "question": "La proposition contient-elle des mots qui inspirent confiance ?",
                            "key": ["service_value", "sender_value_proposition"]
                        },
                        {
                            "question": "Les phrases en général sont-elles chaleureuses ?",
                            "key": ["service_value"]
                        },
                        {
                            "question": "La proposition s’adresse-t-elle directement à {name} ?",
                            "key": ["service_value"]
                        },
                        {
                            "question": "Le contenu donne-t-il l’impression que l’émetteur comprend les besoins spécifiques du destinataire ?",
                            "key": ["service_value", "mail_context", "person_job", "sender_value_proposition"]
                        },
                        {
                            "question": "Y a-t-il une tentative de personnalisation émotionnelle ?",
                            "key": ["service_value", "person_job", "mail_context", "sender_value_proposition"]
                        },
                        {
                            "question": "L’approche semble-t-elle sincère et authentique dans sa manière de se connecter au lecteur ?",
                            "key": ["service_value"]
                        },
                        {
                            "question": "Le message montre-t-il que l’émetteur accorde une importance particulière aux valeurs ou attentes du destinataire ?",
                            "key": ["service_value", "company_value", "person_job", "sender_value_proposition", "mail_context"]
                        },
                        {
                            "question": "Les bénéfices évoqués sont-ils présentés de manière à stimuler le désir de passer à l’action ?",
                            "key": ["service_value", "sender_value_proposition"]
                        },
                        {
                            "question": "Le message met-il en avant un sentiment d’urgence ou d’opportunité ?",
                            "key": ["service_value", "sender_value_proposition"]
                        },
                        {
                            "question": "Les bénéfices évoqués semblent-ils réalistes et atteignables, sans exagération ?",
                            "key": ["service_value", "sender_value_proposition"]
                        },
                        {
                            "question": "Le texte contient-il des éléments de réassurance ?",
                            "key": ["service_value", "sender_value_proposition"]
                        },
                        {
                            "question": "L’émetteur évoque-t-il des réussites ou résultats concrets pour renforcer la crédibilité ?",
                            "key": ["service_value", "sender_value_proposition"]
                        },
                        {
                            "question": "La manière de formuler la proposition inspire-t-elle confiance et professionnalisme ?",
                            "key": ["service_value", "sender_value_proposition"]
                        },
                        {
                            "question": "Le vocabulaire utilisé est-il vivant et expressif plutôt que générique et plat ?",
                            "key": ["service_value", "sender_value_proposition"]
                        },
                        {
                            "question": "Les adjectifs utilisés soulignent-ils les bénéfices de manière efficace et percutante ?",
                            "key": ["service_value", "sender_value_proposition"]
                        },
                        {
                            "question": "L’email donne-t-il au lecteur l’impression d’être traité comme un individu unique ?",
                            "key": ["service_value", "sender_value_proposition"]
                        },
                        {
                            "question": "Le message met-il en avant les conséquences positives d’une action immédiate ?",
                            "key": ["service_value", "sender_value_proposition"]
                        },
                        {
                            "question": "Le message donne-t-il au lecteur une bonne raison de répondre rapidement ?",
                            "key": ["service_value", "sender_value_proposition"]
                        },
                        {
                            "question": "Le message contient-il une preuve de valeur ou un témoignage ?",
                            "key": ["service_value", "sender_value_proposition"]
                        },
                        {
                            "question": "Les engagements ou garanties évoqués sont-ils clairs et crédibles ?",
                            "key": ["service_value", "sender_value_proposition"]
                        }
                    ],
                    "personalization": [
                        {
                            "question": "La proposition mentionne-t-elle un besoin spécifique du destinataire ?",
                            "key": ["service_value", "sender_value_proposition", "person_job"]
                        },
                        {
                            "question": "L’offre est-elle alignée avec les objectifs principaux du destinataire ou de {company} ?",
                            "key": ["service_value", "sender_value_proposition", "company_value"]
                        },
                        {
                            "question": "La proposition montre-t-elle que l’émetteur a fait des recherches sur {name} ou {company} ?",
                            "key": ["service_value", "company_domain_activity"]
                        },
                        {
                            "question": "Les solutions proposées sont-elles pertinentes pour le domaine de {company_domain_activity} ?",
                            "key": ["service_value", "sender_value_proposition", "company_domain_activity"]
                        },
                        {
                            "question": "La proposition fait-elle référence à des éléments propres au {person_job} ou aux responsabilités de {name} ?",
                            "key": ["service_value", "sender_value_proposition", "person_job"]
                        },
                        {
                            "question": "Les besoins liés à la taille ou au modèle économique de {company} sont-ils pris en compte ?",
                            "key": ["service_value", "company_domain_activity", "company"]
                        },
                        {
                            "question": "L’émetteur montre-t-il qu’il comprend les processus ou les structures internes de l’entreprise ?",
                            "key": ["service_value", "sender_value_proposition", "company_domain_activity"]
                        },
                        {
                            "question": "La proposition fait-elle référence à des points de douleur ou des opportunités spécifiques à l’industrie de {company} ?",
                            "key": ["service_value", "sender_value_proposition", "company_domain_activity"]
                        },
                        {
                            "question": "La proposition de valeur est-elle formulée pour répondre à des défis ou des besoins d'entreprise spécifiques ?",
                            "key": ["service_value", "sender_value_proposition", "company_value"]
                        },
                        {
                            "question": "La solution proposée s’ajuste-t-elle aux priorités de l’entreprise du destinataire ?",
                            "key": ["service_value", "sender_value_proposition", "company_value"]
                        },
                        {
                            "question": "La proposition de valeur est-elle ajustée en fonction du rôle ou du poste du destinataire ?",
                            "key": ["service_value", "sender_value_proposition", "person_job"]
                        },
                        {
                            "question": "Le contenu du message reflète-t-il une compréhension du rôle du destinataire dans l’entreprise ?",
                            "key": ["service_value", "sender_value_proposition", "person_job"]
                        },
                        {
                            "question": "La proposition de valeur s'adresse-t-elle spécifiquement aux besoins liés au poste du destinataire ?",
                            "key": ["service_value", "sender_value_proposition", "person_job"]
                        },
                        {
                            "question": "L'email personnalise-t-il la solution en fonction des priorités stratégiques du destinataire ?",
                            "key": ["service_value", "sender_value_proposition", "company_value"]
                        },
                        {
                            "question": "Des publications ou partages récents du destinataire sont-ils intégrés dans la proposition ?",
                            "key": ["service_value", "sender_value_proposition", "mail_context"]
                        },
                        {
                            "question": "La proposition inclut-elle une reconnaissance des actions ou décisions prises récemment par le destinataire ?",
                            "key": ["service_value", "sender_value_proposition", "mail_context"]
                        },
                        {
                            "question": "Les exemples ou illustrations utilisés sont-ils pertinents par rapport aux intérêts personnels ou professionnels du destinataire ?",
                            "key": ["service_value", "sender_value_proposition", "person_job"]
                        },
                        {
                            "question": "La proposition évoque-t-elle un partenariat ou une collaboration possible basé sur des intérêts communs ?",
                            "key": ["service_value", "sender_value_proposition"]
                        },
                        {
                            "question": "Le message mentionne-t-il des outils ou méthodes spécifiques utilisés par le destinataire ou son entreprise ?",
                            "key": ["service_value", "sender_value_proposition", "company_domain_activity"]
                        },
                        {
                            "question": "Le niveau de détail est-il suffisant pour montrer une compréhension approfondie des besoins du destinataire ?",
                            "key": ["service_value", "sender_value_proposition"]
                        },
                        {
                            "question": "La proposition de valeur inclut-elle des termes ou expressions propres au secteur d’activité du destinataire ?",
                            "key": ["service_value", "sender_value_proposition", "company_domain_activity"]
                        },
                        {
                            "question": "La personnalisation semble-t-elle authentique et non générique ?",
                            "key": ["service_value", "sender_value_proposition"]
                        },
                        {
                            "question": "Le message évite-t-il les phrases standardisées ou les formulations trop générales ?",
                            "key": ["service_value", "sender_value_proposition"]
                        },
                        {
                            "question": "L’émetteur montre-t-il un intérêt sincère pour les besoins du destinataire ?",
                            "key": ["service_value", "sender_value_proposition"]
                        },
                        {
                            "question": "Les arguments présentés semblent-ils uniques et spécialement conçus pour ce destinataire ?",
                            "key": ["service_value", "sender_value_proposition"]
                        }
                    ]
                }
            default:
                return {
                    "pertinence": [
                        {
                            "question": "L’accroche mentionne-t-elle le post que la personne a publié sur LinkedIn ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche mentionne-t-elle un sujet abordé dans le post du destinataire ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche mentionne-t-elle un événement ou une activité précisée dans le post ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche reprend-elle des mots-clés du post, tels que {Context_keyword} ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L'accroche mentionne-t-elle un produit ou une solution spécifique ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche reformule-t-elle l’idée principale du post ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche mentionne-t-elle un événement auquel {name} a assisté ou auquel il/elle participera ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche fait-elle allusion à une priorité pour {name} ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche utilise-t-elle des informations tirées du post LinkedIn ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L'accroche respecte-t-elle le sujet principal du post LinkedIn ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L'accroche met-elle en avant un défi, une opportunité ou un succès mentionné dans le post ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche inclut-elle un élément en lien avec son poste ou son entreprise ?",
                            "key": ["accroche", "person_job", "mail_context"]
                        },
                        {
                            "question": "L'accroche met-elle en avant un aspect positif ou un succès présenté dans le post de {name} ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche contient-elle un acronyme présent dans le post ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche mentionne-t-elle {company} ?",
                            "key": ["accroche", "company"]
                        },
                        {
                            "question": "L’accroche utilise-t-elle les termes liés à {company_domain_activity} ?",
                            "key": ["accroche", "company_domain_activity"]
                        },
                        {
                            "question": "Le domaine d’activité est-il utilisé pour établir une connexion avec le destinataire ?",
                            "key": ["accroche", "company_domain_activity"]
                        },
                        {
                            "question": "L’accroche évoque-t-elle des innovations pertinentes pour le secteur {company_domain_activity} ?",
                            "key": ["accroche", "company_domain_activity"]
                        },
                        {
                            "question": "L'accroche traite-t-elle de l’utilisation de l’IA dans le domaine de {company_domain_activity} ?",
                            "key": ["accroche", "company_domain_activity"]
                        },
                        {
                            "question": "L’accroche fait-elle référence à un projet particulier de {name} ?",
                            "key": ["accroche", "mail_context", "person_job"]
                        },
                        {
                            "question": "L’accroche semble-t-elle adaptée pour un {person_job} ?",
                            "key": ["accroche", "mail_context", "person_job"]
                        },
                        {
                            "question": "L'accroche évoque-t-elle des objectifs pour un {person_job} (tels que Croissance, Performance, Stratégie, Innovation, Résultats) ?",
                            "key": ["accroche", "mail_context", "person_job"]
                        },
                        {
                            "question": "L'accroche mentionne-t-elle des défis liés au rôle de {person_job} (tels que objectifs, motivation, marché, compétences et innovation) ?",
                            "key": ["accroche", "mail_context", "person_job"]
                        },
                        {
                            "question": "L'accroche reflète-t-elle les responsabilités de {name} en tant que {person_job} ?",
                            "key": ["accroche", "mail_context", "person_job"]
                        },
                        {
                            "question": "L’accroche donne-t-elle une raison claire pour que {name} se sente personnellement concerné(e) ?",
                            "key": ["accroche", "person_job", "mail_context"]
                        }
                    ],
                    "clarity": [
                        {
                            "question": "L’accroche fait-elle moins de 35 mots ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "Le vocabulaire utilisé est-il compréhensible par le destinataire sans ambiguïté ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L’accroche évite-t-elle les termes trop techniques ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "La phrase est-elle facile à comprendre ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L’accroche utilise-t-elle des mots simples et directs ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "Les phrases de l’accroche s’enchaînent-elles logiquement ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L’accroche suit-elle une progression logique (sujet-verbe-complément) ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "Aucune contradiction n’est-elle présente dans les phrases de l’accroche ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L’accroche évite-t-elle des idées vagues ou des affirmations floues ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "Les phrases restent-elles centrées sur le destinataire ?",
                            "key": ["accroche", "person_job", "mail_context"]
                        },
                        {
                            "question": "L’accroche va-t-elle droit au but ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "Les éléments mentionnés dans l’accroche sont-ils précis ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L’accroche cite-t-elle des termes spécifiques pour un {person_job} ou au {company_domain_activity} ?",
                            "key": ["accroche", "mail_context", "company_domain_activity", "person_job"]
                        },
                        {
                            "question": "Aucune phrase de l’accroche n’est-elle abstraite ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L’accroche contient-elle uniquement les informations essentielles ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche commence-t-elle par une phrase claire ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L’accroche suit-elle une structure logique et fluide ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "Les idées principales sont-elles présentées dès le début de l’accroche ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "Les mots-clés comme {Context_keyword} ou éléments importants apparaissent-ils au début de chaque phrase ?",
                            "key": ["accroche", "mail_context", "company_value"]
                        },
                        {
                            "question": "Aucune partie de l’accroche n’est-elle répétitive ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L’accroche évite-t-elle les acronymes ou abréviations non expliquées ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L’accroche évite-t-elle les répétitions inutiles ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "La construction grammaticale est-elle correcte et fluide ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L’accroche présente-t-elle les informations de manière concise et sans surcharge ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "Les mots utilisés sont-ils précis et évitent-ils les généralités ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche reste-t-elle claire tout en étant personnalisée ?",
                            "key": ["accroche", "mail_context", "person_job"]
                        }
                    ],
                    "captivant": [
                        {
                            "question": "L’accroche suscite-t-elle la curiosité en faisant un lien avec un sujet du destinataire ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche donne-t-elle envie d’en savoir plus sur le sujet abordé ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L'accroche donne-t-elle l'impression qu'il y a quelque chose d'important à découvrir ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L’accroche donne-t-elle l’impression d’un sujet inédit ou novateur ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche met-elle en avant un défi ou un problème à résoudre ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L’accroche mentionne-t-elle un changement ou une nouveauté ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L'accroche commence-t-elle avec une phrase qui intrigue ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L’accroche mentionne-t-elle un événement pertinent par rapport à la proposition de valeur ?",
                            "key": ["accroche", "mail_context", "sender_value_proposition"]
                        },
                        {
                            "question": "L’accroche fait-elle un lien explicite avec le contexte du destinataire ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L'accroche reflète-t-elle une compréhension des défis du destinataire ?",
                            "key": ["accroche", "person_job"]
                        },
                        {
                            "question": "L'accroche donne-t-elle l'impression d'un message personnalisé pour le destinataire ?",
                            "key": ["accroche", "mail_context", "person_job"]
                        },
                        {
                            "question": "L'accroche contient-elle des éléments spécifiques au destinataire qui la différencient d’un message générique ?",
                            "key": ["accroche", "mail_context", "person_job"]
                        },
                        {
                            "question": "L'accroche démontre-t-elle un intérêt pour le destinataire ?",
                            "key": ["accroche", "person_job", "mail_context"]
                        },
                        {
                            "question": "L'accroche donne-t-elle l'impression que l'émetteur connaît bien le contexte du destinataire ?",
                            "key": ["accroche", "person_job", "mail_context"]
                        },
                        {
                            "question": "L'accroche fait-elle sentir au destinataire qu'il est important pour l'émetteur ?",
                            "key": ["accroche", "person_job", "mail_context"]
                        },
                        {
                            "question": "L’accroche adopte-t-elle une approche différente des standards des emails commerciaux ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L’accroche utilise-t-elle un ton, un style ou une structure inhabituels ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L’accroche évite-t-elle des phrases trop commerciales ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L'accroche utilise-t-elle une métaphore ou une image frappante pour se différencier ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L’accroche contient-elle des mots émotionnels forts ?",
                            "key": ["accroche"]
                        },
                        {
                            "question": "L'accroche montre-t-elle une attention particulière aux besoins ou aux intérêts du destinataire ?",
                            "key": ["accroche", "person_job"]
                        },
                        {
                            "question": "L'accroche reflète-t-elle une compréhension approfondie du secteur d’activité du destinataire ?",
                            "key": ["accroche", "company_domain_activity"]
                        },
                        {
                            "question": "L'accroche montre-t-elle que l'émetteur se soucie des objectifs du destinataire ?",
                            "key": ["accroche", "person_job", "mail_context"]
                        },
                        {
                            "question": "L'accroche propose-t-elle quelque chose de concret, sans être trop vague ou générique ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L'accroche fait-elle sentir au destinataire qu'il/elle est au centre de l'attention ?",
                            "key": ["accroche", "person_job", "mail_context"]
                        }
                    ],
                    "personalization": [
                        {
                            "question": "La publication LinkedIn de la personne est-elle mentionnée explicitement dans l'accroche ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "Une phrase spécifique liée au contenu de la publication est-elle utilisée dans l'accroche ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L'accroche reflète-t-elle les idées principales du post que la personne a publié ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L'accroche est-elle en lien avec une compétence ou une expertise spécifique du destinataire ?",
                            "key": ["accroche", "mail_context", "person_job"]
                        },
                        {
                            "question": "L'accroche mentionne-t-elle un élément qui montre que vous avez lu le contenu en détail ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "Y a-t-il une question ou une réflexion en lien direct avec la publication ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "Le sujet de la publication est-il lié à la proposition de valeur de l’email ?",
                            "key": ["accroche", "mail_context", "sender_value_proposition"]
                        },
                        {
                            "question": "Une expression ou un style spécifique au post est-il repris ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L'accroche montre-t-elle un alignement avec l’opinion de la personne dans sa publication ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "Une date ou un contexte spécifique lié à la publication est-il mentionné ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L'accroche reflète-t-elle un lien entre le post et la proposition de valeur ?",
                            "key": ["accroche", "mail_context", "sender_value_proposition"]
                        },
                        {
                            "question": "La publication est-elle utilisée pour ouvrir un dialogue avec la personne ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "La terminologie ou les mots-clés utilisés dans le post apparaissent-ils dans l'accroche ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche évoque-t-elle une idée nouvelle inspirée de la publication ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L'accroche fait-elle preuve d’un effort manifeste pour relier le post au message global ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche contient-elle des références spécifiques au profil ou au post du destinataire ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche reprend-elle un mot-clé important du post ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L'accroche évoque-t-elle une problématique ou une opportunité soulevée dans le post ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche intègre-t-elle des éléments tirés du post publié par le destinataire ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "Le message fait-il ressentir que l’accroche a été rédigée spécifiquement pour cette personne ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche mentionne-t-elle un objectif ou une intention exprimée dans la publication ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "Le timing du post est-il mentionné ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L’accroche contient-elle une réponse explicite au post ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "Une référence à un événement ou une actualité mentionnée dans le post est-elle intégrée ?",
                            "key": ["accroche", "mail_context"]
                        },
                        {
                            "question": "L'accroche évite-t-elle les généralités pour s'appuyer uniquement sur les détails du post ?",
                            "key": ["accroche", "mail_context"]
                        }
                    ]
                }
        }
    };

    const [formData, setFormData] = useState({
        fullMail: "",
        mailSegment: {
            salutation: "",
            accroche: "",
            transition: "",
            service_value: "",
            cta: "",
        },
        mailContext: "",
        priorite: "1",
        categorie: "Post publié",
        person_job: "",
        target_company_name: "",
        target_company_value: "",
        target_company_activity: "",
        sender_value_proposition: "",
        mailSegmentKey: default_mail_segment_key,
        selectedCriteria: default_criteria,
        selectedQuestion: default_question,
        selectedCategory: "Contenu professionnel",
        selectedSubcategory: "Conseils et astuces"
    });
    var questionsData = questionsByCriteria(formData.mailSegmentKey);
    
    const [currentQuestions, setCurrentQuestions] = useState<any>(questionsData[formData.selectedCriteria])
    const [evaluationResults, setEvaluationResults] = useState<any[]>([]);
    const [runLoader, setRunLoader] = useState(false)
    const [showResult, setShowResult] = useState(false)
    const [runFeedbackLoader, setRunFeedbackLoader] = useState<Array<boolean>>([]);

    useEffect(() => {
        // Récupérer les données sauvegardées dans localStorage au chargement
        const storedData = localStorage.getItem("formData");
        if (storedData) {
            setFormData(JSON.parse(storedData));
        }
    }, []);

    const categories: Record<string, string[]> = {
        "1": ["Post publié", "Post republié", "Post partagé"],
        "2": ["Description profil LinkedIn"],
        "3": ["Valeur & service vendu"],
    };

    const mailSegmentValue: Record<string, string> = {
        "accroche": "Accroche",
        "transition": "Transition",
        "service_value": "Proposition de valeur"
    };

    const criteriaOptions: Record<string, Record<string, string>> = {
        "accroche": {
            "pertinence": "Pertinence",
            "clarity": "Clarté",
            "captivant": "Captivant",
            "personalization": "Personnalisation",
        },
        "transition": {
            "coherence": "Cohérence",
            "clarity": "Clarté",
            "fluidity": "Fluidité",
        },
        "service_value": {
            "pertinence": "Pertinence",
            "clarity": "Clarté",
            "emotional_impact": "Impact émotionnel",
            "personalization": "Personnalisation",
        },
    };

    const postCategoryOptions: Record<string, Array<string>> = {
        "Contenu professionnel": [
            "Succès professionnels",
            "Partage d'expérience",
            "Conseils et astuces",
            "Recommandations et témoignages",
            "Blagues et anecdotes"
        ],
        "Contenu personnel": [
            "Articles de blog ou réflexions",
            "Tendances et actualités",
            "Histoires de réussite",
            "Histoires personnelles",
            "Engagement social"
        ],
        "Contenu sur la société ou produit": [
            "Promotion de produits ou services",
            "Campagnes marketing",
            "Collaborations et partenariats"
        ],
        "Événements": [
            "Discussions et débats",
            "Événements et webinaires"
        ]
    }

    const getFirstCriteriaKey = (segment: string): string => {
        const options = criteriaOptions[segment];
        return options ? Object.keys(options)[0] : default_criteria;
    };

    useEffect(() => {
        const storedData = localStorage.getItem("formData");
        if (storedData) {
            const parsedData = JSON.parse(storedData);

            // Récupération des questions associées au segment
            const questionsData = questionsByCriteria(parsedData.mailSegmentKey);
            const associatedCriteria = parsedData.selectedCriteria || getFirstCriteriaKey(parsedData.mailSegmentKey);
            const associatedQuestionList = questionsData ? questionsData[associatedCriteria] : [];

            // Sélection de la question : celle du stockage ou la première question disponible
            const selectedQuestion = parsedData.selectedQuestion && parsedData.selectedQuestion.trim() !== ""
                ? parsedData.selectedQuestion
                : (associatedQuestionList.length > 0 ? associatedQuestionList[0].question : "");

            setCurrentQuestions(associatedQuestionList);

            setFormData({
                ...parsedData,
                selectedCriteria: associatedCriteria,
                selectedQuestion: selectedQuestion
            });
        }
    }, []);


    const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleMailSegmentChange = (
        e: ChangeEvent<HTMLSelectElement>
    ) => {
        const { value } = e.target;
        const updatedQuestionsData = questionsByCriteria(value);

        if (updatedQuestionsData) {
            const newCriteria = getFirstCriteriaKey(value);
            const newQuestions = updatedQuestionsData[newCriteria];
            setCurrentQuestions(newQuestions);

            setFormData((prev) => ({
                ...prev,
                mailSegmentKey: value,
                selectedCriteria: newCriteria,
                selectedQuestion: newQuestions[0]?.question || ""
            }));
        }
    };

    const handleCriteriaChange = (
        e: ChangeEvent<HTMLSelectElement>
    ) => {
        const { value } = e.target;
        const questionListWithCriteria = questionsByCriteria(formData.mailSegmentKey);

        if (questionListWithCriteria) {
            const newQuestions = questionListWithCriteria[value];
            setCurrentQuestions(newQuestions);

            setFormData((prev) => ({
                ...prev,
                selectedCriteria: value,
                selectedQuestion: newQuestions[0]?.question || ""
            }));
        }
    };

    const handleMailDecomposition = (): {
        salutation: string;
        accroche: string;
        transition: string;
        service_value: string;
        cta: string;
    } => {
        const fullMail = formData.fullMail;
        let paragraphs: string[] = [];

        if (fullMail.includes("\n\n")) {
            paragraphs = fullMail
                .split("\n\n")
                .map((p) => p.trim())
                .filter((p) => p.length > 0);
        } else {
            paragraphs = fullMail
                .split("\n")
                .map((p) => p.trim())
                .filter((p) => p.length > 0);
        }

        let salutation = "";
        let accroche = "";
        let transition = "";
        let service_value = "";
        let cta = "";

        if (paragraphs.length > 0) {
            salutation = paragraphs[0];
        }

        if (paragraphs.length > 1) {
            const part = paragraphs[1];
            const separatorIndex = part.indexOf(". ");
            if (separatorIndex !== -1) {
                accroche = part.substring(0, separatorIndex + 1).trim();
                transition = part.substring(separatorIndex + 1).trim();
            } else {
                accroche = part;
            }
        }

        if (paragraphs.length > 2) {
            service_value = paragraphs[2];
        }

        if (paragraphs.length > 3) {
            cta = paragraphs[3];
        }

        return { salutation, accroche, transition, service_value, cta };
    };

    const handleSubmit = () => {
        setRunLoader(true)

        const mailSegmented = handleMailDecomposition();

        const input_data = {
            mail_segment: mailSegmented,
            summary: {
                "priorité": Number(formData.priorite),
                "catégorie": formData.categorie,
                "résumé": formData.mailContext,
            },
            target_company_name: formData.target_company_name,
            target_company_value: formData.target_company_value,
            target_company_activity: formData.target_company_activity,
            person_job: formData.person_job,
            sender_value_proposition: formData.sender_value_proposition,
        };

        // Sauvegarder les données dans localStorage à chaque changement
        localStorage.setItem("formData", JSON.stringify(formData));

        let question_data: Record<string, { question: string; key: string[] }[]> = {};

        if (currentQuestions) {
            question_data[formData.selectedCriteria] = currentQuestions.filter(
                (q: any) => q.question === formData.selectedQuestion
            );
        }

        const data2send = {
            'input_data': input_data,
            'question_key_data': question_data,
            'segment_key': formData.mailSegmentKey
        }
        // console.info("Data2send: ", data2send)
        // return
        sendMailEvaluation4Bart(data2send).then((rep: any) => {
            const response_data_body = rep.data.body;

            if (response_data_body && Array.isArray(response_data_body)) {
                setEvaluationResults(prevResults => [...prevResults, ...response_data_body]); // Met à jour l'affichage des résultats
                // Ajout d'un nouveau status loader
                setRunFeedbackLoader((prevState) => [...prevState, false]);
                setShowResult(true)

                setTimeout(() => {
                    resultRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
                }, 100);
            }

            setRunLoader(false);
        }).catch((error) => {
            console.error("Erreur lors de l'envoi :", error);
            setRunLoader(false);
        });
    };

    const handleFeedback = (responseChoosed: boolean, idx: number) => {
        // Mettre à jour le loader associé au champ de feedback touché (like ou dislike)
        setRunFeedbackLoader((prevState) =>
            prevState.map((item, index) => (index === idx ? true : item))
        );
        // Vérification de la véraction de la prédiction
        let responseValidity = ""
        if (responseChoosed) {
            // La personne a validée la réponse fournie par le model, donc on la garde
            responseValidity = evaluationResults[idx]['réponse']
        } else {
            // Si la réponse est 'Oui' mais le feedback est 'false', on change la valeur par 'Non'. Et vice-versa
            responseValidity = evaluationResults[idx]['réponse'] === "Oui" ? "Non" : "Oui"
        }
        // console.log("eval: ", evaluationResults)
        const data2send = {
            text: evaluationResults[idx]['prompt'],
            label: responseValidity,
            feedback_value: true,
            question: evaluationResults[idx]['question'],
            mail_segment: evaluationResults[idx]['mail_segment'],
            criteria: evaluationResults[idx]['criteria'],
            priority: `priorite_${formData.priorite}`
        }
        // console.log("Feedback data: ", data2send)
        // return 
        updateScoringData4Feedback(data2send).then((rep: any) => {
            const response_data_body = rep.data.body;
            // console.info("Body from server: ", rep)
            if (response_data_body) {
                evaluationResults.forEach((value, index) => {
                    if (index === idx) {
                        let temp = evaluationResults

                        temp[index]['réponse'] = responseValidity
                        if (response_data_body['status'] === "success") {
                            temp[index]['feedback_status'] = true
                        } else {
                            temp[index]['feedback_status'] = false
                        }

                        setEvaluationResults(temp)
                    }
                })
            }

            // Mettre à jour le loader associé au champ de feedback touché (like ou dislike) en false
            setRunFeedbackLoader((prevState) =>
                prevState.map((item, index) => (index === idx && false))
            );
        }).catch((error) => {
            console.error("Erreur lors de l'envoi :", error);
            // Mettre à jour le loader associé au champ de feedback touché (like ou dislike) en false
            setRunFeedbackLoader((prevState) =>
                prevState.map((item, index) => (index === idx && false))
            );
        });
    };


    return (
        <div className={`email-form-wrapper ${showResult ? "email-form-split" : "email-form-center"}`}>
            <div className="email-form-container">
                <div className="email-form-header">
                    <h2>Formulaire d'évaluation de mail</h2>
                </div>

                <section className="email-form-section">
                    <h3>Mail</h3>
                    <textarea
                        name="fullMail"
                        value={formData.fullMail}
                        onChange={handleChange}
                        className="email-form-textarea"
                        placeholder="Saisissez le mail entier..."
                        rows={6}
                    />
                </section>

                <section className="email-form-section">
                    <h3>Destinataire</h3>
                    <input
                        name="person_job"
                        value={formData.person_job}
                        onChange={handleChange}
                        className="email-form-input"
                        placeholder="Poste de la personne cible"
                    />
                    <input
                        name="target_company_name"
                        value={formData.target_company_name}
                        onChange={handleChange}
                        className="email-form-input"
                        placeholder="Nom de l'entreprise"
                    />
                    <input
                        name="target_company_value"
                        value={formData.target_company_value}
                        onChange={handleChange}
                        className="email-form-input"
                        placeholder="Valeur de l'entreprise cible"
                    />
                    <input
                        name="target_company_activity"
                        value={formData.target_company_activity}
                        onChange={handleChange}
                        className="email-form-input"
                        placeholder="Activité/Secteur de l'entreprise cible"
                    />
                </section>

                <section className="email-form-section">
                    <h3>Émetteur</h3>
                    <textarea
                        name="sender_value_proposition"
                        value={formData.sender_value_proposition}
                        onChange={handleChange}
                        className="email-form-textarea"
                        placeholder="Proposition de valeur de l'entreprise émetteur"
                        rows={6}
                    />
                </section>

                <section className="email-form-section">
                    <h3>Priorité</h3>
                    <select
                        name="priorite"
                        value={formData.priorite}
                        onChange={handleChange}
                        className="email-form-select"
                    >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>

                    <h3>Type</h3>
                    <select
                        name="categorie"
                        value={formData.categorie}
                        onChange={handleChange}
                        className="email-form-select"
                    >
                        {categories[formData.priorite]?.map((cat, index) => (
                            <option key={index} value={cat}>{cat}</option>
                        ))}
                    </select>
                </section>

                <section className="email-form-section">
                    <h3>Contexte (Post, description, info site internet)</h3>
                    <textarea
                        name="mailContext"
                        value={formData.mailContext}
                        onChange={handleChange}
                        className="email-form-textarea"
                        placeholder="Saisissez ici le contexte"
                        rows={6}
                    />

                    <label className="font-semibold">Catégorie :</label>
                    <select
                        name="selectedCategory"
                        className="email-form-select"
                        value={formData.selectedCategory}
                        onChange={handleChange}
                    >
                        <option value="" disabled>Choisir une catégorie</option>
                        {Object.keys(postCategoryOptions).map((category) => (
                            <option key={category} value={category}>{category}</option>
                        ))}
                    </select>

                    <h3>Sous-catégorie :</h3>
                    <select
                        name="selectedSubcategory"
                        className="email-form-select"
                        value={formData.selectedSubcategory}
                        onChange={handleChange}
                        disabled={!formData.selectedCategory}
                    >
                        <option value="" disabled>Choisir une sous-catégorie</option>
                        {formData.selectedCategory && postCategoryOptions[formData.selectedCategory].map((subcategory) => (
                            <option key={subcategory} value={subcategory}>{subcategory}</option>
                        ))}
                    </select>
                </section>

                <section className="email-form-section">
                    <h3>Segment de mail</h3>
                    <select
                        name="mailSegmentKey"
                        value={formData.mailSegmentKey}
                        onChange={handleMailSegmentChange}
                        className="email-form-select"
                    >
                        <option value="accroche">Accroche</option>
                        <option value="transition">Transition</option>
                        <option value="service_value">Proposition de valeur</option>
                    </select>
                </section>

                <section className="email-form-section">
                    <h3>Critère d'évaluation</h3>
                    <select
                        name="selectedCriteria"
                        value={formData.selectedCriteria}
                        onChange={handleCriteriaChange}
                        className="email-form-select"
                    >
                        {Object.entries(criteriaOptions[formData.mailSegmentKey] || {}).map(
                            ([key, label]) => (
                                <option key={key} value={key}>{label}</option>
                            )
                        )}
                    </select>
                </section>

                <section className="email-form-section">
                    <h3>Questions</h3>
                    <select
                        name="selectedQuestion"
                        value={formData.selectedQuestion}
                        onChange={handleChange}
                        className="email-form-select"
                    >
                        {currentQuestions.map((q: any, index: number) => (
                            <option key={index} value={q.question}>{q.question}</option>
                        ))}
                    </select>
                </section>

                {runLoader ? (
                    <div className="email-form-loader">
                        <LoaderResponse colorValue={COLORS.jauneClaire} w={20} h={20} />
                        <p>Envoi en cours...</p>
                    </div>
                ) : (
                    <div className="email-form-footer">
                        <button type="submit" onClick={handleSubmit} className="email-form-button">
                            Envoyer
                        </button>
                    </div>
                )}
            </div>

            {/* Section Résultats - 1/3 de l'écran */}
            {evaluationResults.length > 0 && (
                <div ref={resultRef} className="email-result-container">
                    <h2 className="email-result-title">Résultats de l'évaluation</h2>
                    {showResult && evaluationResults.length > 0 ? (
                        <ul className="email-result-list">
                            {
                                evaluationResults.map((res, index) => (
                                    <li key={index} className="email-result-item" style={
                                        !res.feedback_status ? { background: "white" }
                                            : { background: res.réponse === "Oui" ? "rgba(33, 180, 45, 0.4)" : res.réponse === "Non" ? "#fb000066" : "white" }
                                    }>
                                        <p className="email-result-question">{res.question}</p>
                                        <p>Segment: <span className="email-result-answer">{mailSegmentValue[res.mail_segment]}</span></p>
                                        <p>Critère: <span className="email-result-answer">{criteriaOptions[res.mail_segment][res.criteria]}</span></p>
                                        <p>Réponse: <span className="email-result-answer" style={{ color: res.réponse === "Oui" ? "green" : "red" }}>
                                            {res.réponse}
                                        </span>
                                        </p>
                                        {/* <p>Score: <span className="email-result-score">{(res.score * 100).toFixed(1)}%</span></p> */}
                                        {
                                            !res.feedback_status && runFeedbackLoader[index] ? <div className="email-form-loader">
                                                <LoaderResponse colorValue={COLORS.grisClaire} w={20} h={20} />
                                                <p>validation en cours...</p>
                                            </div>
                                                : (
                                                    !res.feedback_status ?
                                                        <div className="mt-4 feedback-container">
                                                            <p className="text-sm text-gray-600 mb-2">Cette réponse vous semble-t-elle correcte ?</p>
                                                            <FeedbackChoice id={index} onFeedback={handleFeedback} />
                                                        </div>
                                                        : (
                                                            <div className="mt-4 feedback-container">
                                                                <p className="text-eval text-sm text-gray-600 mb-2">Déjà évalué</p>
                                                            </div>
                                                        )
                                                )
                                        }
                                    </li>
                                ))}
                        </ul>
                    ) : (
                        <p className="email-result-empty">Aucun résultat disponible</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default BartTestEmailForm;
