import React from "react";
import { Route, Routes } from "react-router-dom";

import ErrorPage from "../pages/NotFound";
import Dash from "../pages/AureliaDashB";
import Information from "../pages/Information";
import PageChecker from "../pages/LoadingPageChecker";
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import DashRedirection from "../pages/Aurelia/AurDashRedPage";
import WelcomePage from "../pages/Accueil/WelcomingPage";
import CodeVerificationPage2 from "../pages/Login/CodeVerificationVIew2";
import Mail2GenHistory from "../pages/Mail2GenHistory";
import BartTestEmailForm from "../pages/UserSpace/BartModelTest";

// import Mail2GenHistory from "../pages/Mail2GenHistory";

const AllRoutes = () => {

  return (
    <Routes>
      <Route path="/" element={<PageChecker />} />
      <Route path="*" element={<ErrorPage />} />
      <Route path="/welcoming" element={<WelcomePage />} />
      <Route path="/info/:model_name" element={<Information />} />
      <Route path="/Aurelia/Dashboard/:page_name/:id/" element={<Dash />} />
      <Route path="/Aurelia/Campaign/:page_name/:id/" element={<Dash />} />
      <Route path="/Aurelia/PromptHistory" element={<Mail2GenHistory />} />
      <Route path="/login" element={<PageChecker />} />
      <Route path="/linkedin" element={<LinkedInCallback />} />
      <Route path="/code" element={<CodeVerificationPage2 />} />
      <Route path="/cancel" element={<DashRedirection url_route="Tarifs"/>} />
      <Route path="/test-bart" element={<BartTestEmailForm/>} />
    </Routes>
  )
}

export default AllRoutes