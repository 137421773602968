// FeedbackChoice.tsx
import React from 'react';

interface FeedbackChoiceProps {
  onFeedback: (responseChoosed: boolean, index: number) => void;
  id: number;
}

const FeedbackChoice: React.FC<FeedbackChoiceProps> = ({ onFeedback, id}) => {
    const like = "/images/icone/feedback/green_like.png"
    const dislike = "/images/icone/feedback/red_dislike.png"

  return (
    <div className="flex gap-4 items-center">
      <button
        onClick={() => onFeedback(true, id)}
        className="feedback-btn group flex items-center justify-center w-10 h-10 rounded-full hover:bg-green-50 transition-all"
        aria-label="Bonne réponse"
      >
        <img
            alt="Bonne"
            style={{ borderColor: '#d01212', cursor: 'pointer' }}
            className="microphone-icon"
            src={like}
            width={30} height={30}
        />
      </button>

      <button
        onClick={() => onFeedback(false, id)}
        className="feedback-btn group flex items-center justify-center w-10 h-10 rounded-full hover:bg-red-50 transition-all"
        aria-label="Mauvaise réponse"
      >
        <img
            alt="Mauvaise"
            style={{ borderColor: '#d01212', cursor: 'pointer' }}
            className="microphone-icon"
            src={dislike}
            width={30} height={30}
        />
      </button>
    </div>
  );
};

export default FeedbackChoice;